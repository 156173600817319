<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <md-icon class="metismenu-icon">pin</md-icon>
          </div>
          <div class="text-black">
            Product Category
            <div class="page-title-subheading">Add Category for Product</div>
          </div>
        </div>
      </div>
    </div>
    <md-card md-with-hover class="round-card mb-5">
      <md-ripple>
        <md-card-header>
          <h3>Add Category</h3>
        </md-card-header>
        <md-card-content>
          <div class="container">
            <div class="row">
              <div class="col-md-12">
                <md-field>
                  <label>Categrory Name</label>
                  <md-input v-model="form.name"></md-input>
                </md-field>
              </div>
            </div>

            <div class="text-center">
              <md-button class="md-raised md-primary md-dense" @click="SubmitForm()">SUBMIT</md-button>
            </div>
          </div>

        </md-card-content>
      </md-ripple>
    </md-card>


    <md-card md-with-hover class="round-card mb-5 mt-5">
      <md-ripple>

        <md-card-content>
          <div class="container text-center">
            <md-table>
              <md-table-row>
                <md-table-head class="text-center" md-numeric>ID</md-table-head>
                <md-table-head class="text-center">Name</md-table-head>
                <md-table-head class="text-center">Created By</md-table-head>
                <md-table-head class="text-center">Status</md-table-head>
                <md-table-head v-if="$STORE.state.user.type == 'admin'" class="text-center">Action
                </md-table-head>
              </md-table-row>
              <md-table-row v-for="row in categories" :key="row.id">
                <md-table-cell class="text-center" md-numeric> {{ row.id }}</md-table-cell>
                <md-table-cell class="text-center">{{ row.name }}</md-table-cell>
                <md-table-cell class="text-center">{{ row.user.username }}</md-table-cell>
                <md-table-cell class="text-center">
                  <md-chip :class="getStatusClass(row.status)">{{ getStatusText(row.status) }}</md-chip>
                </md-table-cell>
                <md-table-cell v-if="$STORE.state.user.type == 'admin'" class="text-center">
                  <div
                      class="btn-group"
                      role="group"
                      aria-label="Basic example"
                  >

                    <md-button
                        class="md-fab md-mini md-accent"
                        @click="deleteCategory(row.id)"
                    >
                      <md-icon>delete</md-icon>
                      <md-tooltip md-direction="bottom"
                      >Delete Category
                      </md-tooltip
                      >
                    </md-button>

                  </div>
                </md-table-cell>
              </md-table-row>
            </md-table>

            <div class="col-md-12 mb-3 mt-3">
              <div class="justify-content-center" style="display: flex !important;">
                <div>
                  <pagination
                      :data="pagination"
                      @pagination-change-page="getCategories"
                      :limit="5"
                      page="1"
                  ></pagination>
                </div>

              </div>
            </div>
          </div>

        </md-card-content>
      </md-ripple>
    </md-card>

  </div>
</template>

<script>

import {deleteCategory, getCategories, saveCategory} from "../../../services/settings/settingService";

export default {
  data() {
    return {
      categories: [],
      pagination: {},
      form: {
        name: '',
      }
    };
  },

  methods: {

    getStatusClass(status) {
      if (status == '1') { //active
        return "bg-success";
      } else if (status == "0") { //de-active
        return "md-primary";
      } else if (status == "2") { //deleted
        return "md-accent";
      }
    },

    getStatusText(status) {
      if (status == '1') { //active
        return "Active";
      } else if (status == "0") { //unactive
        return "Pending";
      }
    },

    prepPagination(data) {
      this.pagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    getCategories(page = 1) {
      getCategories(page)
          .then((response) => {
            this.prepPagination(response.data);
            this.categories = response.data.data;
            this.$notify({
              message: response.data.message,
              type: "success",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    SubmitForm() {
      saveCategory(this.form)
          .then((response) => {
            this.$notify({
              message: response.data.message,
              type: "success",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
            this.getCategories()
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },


    deleteCategory(id) {
      deleteCategory(id).then(() => {
        this.getCategories()
      })
    }
  },

  mounted() {
    this.getCategories();
  }

};

</script>