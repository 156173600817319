/* eslint-disable */
// noinspection DuplicatedCode

import {Form} from 'vform'
import axios from "axios";
import store from "@/store";

let BASE_API_URL;
let BASE_ASSETS_URL;
const form = new Form();

const GET_HEADERS = {
    headers: {
        "Authorization": 'Bearer ' + store.getters.isAuthenticated,
    }
};

const POST_HEADERS = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.getters.isAuthenticated
    }
};



    BASE_API_URL = process.env.VUE_APP_API_BASE_URL;
    BASE_ASSETS_URL = process.env.VUE_APP_API_ASSET_BASE_URL;



export function getImage(path, data) {
    return BASE_ASSETS_URL + path + data;
}