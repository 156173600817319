<template>
  <div>
    <md-dialog :md-active.sync="showDialog" class="round-card">
      <md-dialog-title>{{ title }}</md-dialog-title>

      <md-dialog-content>
        <div class="container">
          <div class="col-md-12">
            <div v-if="all_images" class="row">
                <div v-for="image in all_images" :key="image" :class="all_images.length > 1 ? 'col-md-6' : 'col-md-12' " class="mt-2">
                  <md-card>
                    <md-card-content class="image_card">
                      <div class="container">
                        <div>
                          <img :src="getImagePath(image)" alt="">
                        </div>
                      </div>
                    </md-card-content>
                  </md-card>
                </div>
            </div>
            <div v-if="text" class="mt-2">
              <md-card>
                <md-card-content class="image_card">
                  <div class="container">
                    <div>
                      {{ text }}
                    </div>
                  </div>
                </md-card-content>
              </md-card>
            </div>
          </div>
        </div>
        <md-dialog-actions>
          <md-button class="md-primary" @click="showDialog = false">Close</md-button>
        </md-dialog-actions>
      </md-dialog-content>

    </md-dialog>

    <md-button @click="showDialog = true" class="md-icon-button md-raised md-primary">
      <md-icon>visibility</md-icon>
    </md-button>

  </div>
</template>

<script>
import {getImage} from "@/services/utils/imageViewerService";

export default {
  name: "tableImageViewerComponent",
  props: {
    title: String,
    images: String,
    text: String
  },
  data: () => ({
    showDialog: false,
    all_images: [],
    path: '',
  }),

  methods: {
    setPath(data) {
      if (data === 'Product Image') {
        this.path = 'images/product_picture/'
      } else if (data === 'Product Description Images') {
        this.path = 'images/product_description_picture/'
      }
    },
    getImagePath(data) {
      // return this.production ? this.$BASE_ASSETS_URL + 'profile_picture/' + image : this.$LOCAL_ASSETS_URL + 'profile_picture/' + image;
      return getImage(this.path, data)
    }
  },

  created() {
    this.all_images = this.images.split(',');
    this.setPath(this.title)
    // console.log(this.all_images)
  }
}
</script>

<style scoped>
.image_card {
  width: 100%;
}
</style>