<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">textsms</md-icon>
          </div>
          <div class="text-black">Send Bulk SMS
            <div class="page-title-subheading">
              Send SMS to Valid Phone Numbers for Newsletters
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>
            <md-card-header>
              <div class="md-title text-capitalize">Send SMS to Lead Phone Numbers</div>
            </md-card-header>

            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="row">

                    <div class="col-md-6">
                      <md-field>
                        <label>Phone Numbers</label>
                        <md-input v-model="form.numbers" placeholder="+12039991528,+12039992337"></md-input>
                        <span class="md-helper-text">Input 500 Numbers at once</span>
                      </md-field>
                    </div>

                    <div class="col-md-6">
                      <md-field>
                        <label>Message Body</label>
                        <md-textarea v-model="form.message_body" md-autogrow></md-textarea>
                      </md-field>
                    </div>


                  </div>

                </div>


                <div class="mt-5">
                  <div class="text-center">
                    <!--                      <md-button disabled="" @click="submit()" class="md-raised text-white"-->
                    <!--                                 style="background-color: green !important;">-->
                    <!--                        Send SMS with twilio-->
                    <!--                      </md-button>-->
                    <!--                      <md-button @click="submitVonage()" class="md-raised text-white"-->
                    <!--                                 style="background-color: green !important;">-->
                    <!--                        Send SMS with Vonage-->
                    <!--                      </md-button>-->
                    <md-button @click="submitOnBuka()" class="md-raised text-white"
                               style="background-color: green !important;">
                      Send SMS
                    </md-button>

                  </div>

                </div>

              </div>

              <div class="container mt-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="d-flex justify-content-between align-content-between">
                      <div class="text-primary font-weight-bold">
                        Total : {{ total_numbers }}
                      </div>
                      <div class="text-success font-weight-bold">
                        Successful : {{ results.length }}
                      </div>
                      <div class="text-danger font-weight-bold">
                        Failed : {{ total_numbers - results.length }}
                      </div>

                    </div>
                    <div class="mt-5">
                      Key : 0 = Delivered, -1 = Sending, 1 = Failed
                    </div>
                  </div>

                </div>

              </div>

              <div class="mt-3">
                <div>
                  <md-table>
                    <md-table-row>
                      <md-table-head md-numeric>ID</md-table-head>
                      <md-table-head>Number</md-table-head>
                      <md-table-head>Received Time</md-table-head>
                      <md-table-head>Status</md-table-head>
                    </md-table-row>


                    <md-table-row v-for="(data, row) in results" :key="data.id">

                      <md-table-cell md-numeric>{{ row + 1 }}</md-table-cell>
                      <md-table-cell>{{ data.number }}</md-table-cell>
                      <md-table-cell>{{ data.receiveTime }}</md-table-cell>
                      <md-table-cell>{{ data.status }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>


            </md-card-content>
          </md-ripple>
        </md-card>
      </div>


    </div>


  </div>
</template>

<script>
import {sendSms, sendSms_v1, sendSms_v2} from "@/services/sms/smsService";

export default {
  name: "SendSmsComponent",
  data() {
    return {
      // eslint-disable-next-line no-undef
      form: {
        numbers: "",
        message_body: "",
      },
      index: 1,
      results: [],
      filtered_results: [],
      filtered: false,
      submitting: false,
      total_numbers: 0,
    }
  },

  methods: {
    submit() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        sendSms(this.form)
            .then((response) => {

              loader.hide();
              // this.convert(response.data.data);

              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })


            })
            .catch((error) => {
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 1000)
    },

    submitVonage() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        sendSms_v1(this.form)
            .then((response) => {
              loader.hide();
              // this.convert(response.data.data);
              this.results = response.data.data;
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })

            })
            .catch((error) => {
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 1000)
    },

    submitOnBuka() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        sendSms_v2(this.form)
            .then((response) => {
              loader.hide();
              // this.convert(response.data.data);
              this.results = response.data.data.data;
              this.total_numbers = response.data.data.total_number
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })

            })
            .catch((error) => {
              console.log(error.response);
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 1000)
    },

    convert(inputObj) {
      const newArr = Object.values(inputObj);
      newArr.forEach(obj => {
        if (obj.descendants) obj.descendants = this.filter_by_type_mobile(obj.descendants);
        if (obj.children) obj.children = this.filter_by_type_mobile(obj.children);
      });
      return this.results = newArr;

    },

    filter(data) {
      this.filtered = true;
      // alert(data);
      this.filtered_results = this.results.filter(object => {
        return object.carrier.type == data;
      });
    },

    reset_filter() {
      this.filtered = false;
      this.filtered_results = [];

    }
  }
}
</script>

<style scoped>


</style>