<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">ondemand_video</md-icon>
          </div>
          <div>Buy Links
            <div class="page-title-subheading">Buy Links
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Table Code Start here-->
    <div class="row">
      <div v-if="$STORE.state.user.type == 'user'" class="col-md-12">
        <md-card md-with-hover class="round-card mb-5">
          <md-ripple>
            <md-card-header>
              <div class="md-title"> Buy Valid Links</div>
            </md-card-header>
            <md-card-content>
              <div class="mt-5 mb-5">
                <form @submit.prevent="linkPurchase()">
                  <div class="container">
                    <div class="row">

                      <div class="col-md-12">
                        <md-field>
                          <label for="movie">Select Page</label>
                          <md-select v-model="form.link_id" name="movie" id="bank">
                            <md-option v-for="bank in banks" :value="bank.id" :key="bank.id">{{ bank.name }} @
                              ${{ bank.price }}
                            </md-option>
                          </md-select>
                        </md-field>
                      </div>

                      **All links are valid for just 7 days
                    </div>

                    <div class="float-right">
                      <md-button type="submit" class="md-raised md-dense md-primary">Buy</md-button>
                    </div>
                  </div>
                </form>
              </div>
            </md-card-content>
          </md-ripple>
        </md-card>

      </div>

      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5">
          <md-ripple>
            <md-card-content>
              <div class="mt-5">
                <div v-if="PurchasedLinks.length < 1">
                  <noDataComponent />
                </div>
              </div>
              <div v-if="PurchasedLinks.length >= 1">
                <md-table>
                  <md-table-row>
                    <md-table-head class="text-center" md-numeric>ID</md-table-head>
                    <md-table-head class="text-center">Link Name</md-table-head>
                    <md-table-head class="text-center">User Name</md-table-head>
                    <md-table-head class="text-center">Status</md-table-head>
                    <md-table-head class="text-center">Links</md-table-head>
                    <md-table-head class="text-center">Date</md-table-head>
                    <md-table-head class="text-center" v-if="$STORE.state.user.type == 'admin'">Actions</md-table-head>
                    <md-table-head class="text-center" v-if="$STORE.state.user.type == 'admin'">Upload Links
                    </md-table-head>
                  </md-table-row>


                  <md-table-row v-for="(data, row) in PurchasedLinks" :key="data.id">

                    <md-table-cell class="text-center" md-numeric>{{ row + 1 }}</md-table-cell>
                    <md-table-cell class="text-center">{{ data.link_bank.name }}</md-table-cell>
                    <md-table-cell class="text-center">{{ data.user.username }}</md-table-cell>
                    <md-table-cell class="text-center">
                      <md-chip :class="getStatusClass(data.status)">{{ data.status }}</md-chip>
                    </md-table-cell>
                    <md-table-cell class="text-center" v-if="data.link">
                      <div style=" max-height: 246px !important; max-width: 200px; overflow-y: auto">
                        {{ data.link }}
                      </div>


                    </md-table-cell>
                    <md-table-cell class="text-center" v-if="!data.link">
                      <md-chip class="md-accent">Not Available</md-chip>
                    </md-table-cell>
                    <md-table-cell class="text-center">{{ data.created_at | datetime }}</md-table-cell>

                    <md-table-cell v-if="$STORE.state.user.type == 'admin'" class="text-center">

                      <div
                          class="btn-group"
                          role="group"
                          aria-label="Basic example"
                      >

                        <md-button
                            v-if="data.status == 'pending' || data.status == 'failed' "
                            class="md-fab md-mini bg-success"
                            @click="approve(data.id)"

                        >
                          <md-icon>check_circle</md-icon>
                          <md-tooltip md-direction="bottom"
                          >Approve Transaction
                          </md-tooltip
                          >
                        </md-button>

                        <md-button
                            v-if="data.status == 'pending' || data.status == 'approved' "
                            class="md-fab md-mini bg-danger"
                            @click="decline(data.id)"

                        >
                          <md-icon>close</md-icon>
                          <md-tooltip md-direction="bottom"
                          >Decline Transaction
                          </md-tooltip
                          >
                        </md-button>

                      </div>

                    </md-table-cell>

                    <md-table-cell v-if="data.status !== 'approved' && $STORE.state.user.type == 'admin' "
                                   class="text-center">
                      <md-chip class="md-accent">Not Available</md-chip>
                    </md-table-cell>

                    <md-table-cell v-if="data.status === 'approved' && $STORE.state.user.type == 'admin'"
                                   class="text-center">

                      <div>
                        <md-field>
                          <label>Input Links</label>
                          <md-textarea v-model="form.links"
                                       placeholder="e.g https://www.lolx.com, https://www.lolx.com,"></md-textarea>
                          <md-icon>description</md-icon>
                        </md-field>

                        <md-button @click="uploadLink(data.id)" class="md-raised md-dense bg-success">Send Links
                        </md-button>
                      </div>

                    </md-table-cell>


                  </md-table-row>

                </md-table>
              </div>
            </md-card-content>
          </md-ripple>
        </md-card>

      </div>
    </div>


  </div>
</template>

<script>
import {
  changeTransactionStatus,
  getLinkBanks,
  linkPurchase,
  loadTransactions, uploadLinkPurchase
} from "@/services/market/marketService";
import noDataComponent from "../../utils/noDataComponent.vue";

export default {
  name: "BuyLinksComponent",
  components: {noDataComponent},
  data() {
    return {
      submitting: false,
      filter: "All",
      pagination: {},
      banks: [],
      PurchasedLinks: [],
      // eslint-disable-next-line no-undef
      form: {
        link_id: "",
        links: "",
      },
    };
  },

  methods: {
    getStatusClass(status) {
      if (status == 'approved') {
        return "bg-success";
      } else if (status == "pending") {
        return "md-primary";
      } else if (status == "failed") {
        return "md-accent";
      }
    },
    // getValidationClass(fieldName) {
    //   const field = this.form.errors.has(fieldName);
    //   if (field) {
    //     return "md-invalid";
    //   }
    // },

    linkPurchase() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        linkPurchase(this.form)
            .then((response) => {
              loader.hide();
              this.$Progress.finish();
              this.submitting = false;
              this.loadTransactions();
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              });
            })
            .catch((error) => {
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                this.form.errors.set(error.response.data.error);
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 2000)
    },

    getBanks() {
      getLinkBanks()
          .then((response) => {
            this.banks = response.data.data;
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    prepPagination(data) {
      this.pagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    loadTransactions(page = 1) {
      loadTransactions(page).then((response) => {
        this.prepPagination(response.data);
        this.PurchasedLinks = response.data.data;
      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      })
    },

    approve(id) {
      changeTransactionStatus(id,'approve')
          .then(() => {
            this.loadTransactions();
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    decline(id) {
      changeTransactionStatus(id,'decline')
          .then(() => {
            this.loadTransactions();
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },


    uploadLink(id) {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        uploadLinkPurchase(this.form, id)
            .then((response)=>{
              loader.hide();
              this.$Progress.finish();
              this.submitting = false;
              this.loadTransactions();
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              });

            })
            .catch((error)=>{
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                this.form.errors.set(error.response.data.error);
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 2000)
    },

  },

  created() {
    this.getBanks();
    this.loadTransactions();
  }
}
</script>

<style scoped>

</style>