import { render, staticRenderFns } from "./SmtpToSmsTransactions.vue?vue&type=template&id=3ab4c5ea&scoped=true&"
import script from "./SmtpToSmsTransactions.vue?vue&type=script&lang=js&"
export * from "./SmtpToSmsTransactions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ab4c5ea",
  null
  
)

export default component.exports