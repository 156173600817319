/* eslint-disable */
// noinspection DuplicatedCode

import {Form} from 'vform'
import axios from "axios";
import {GET_HEADERS, POST_HEADERS, SMTP_GET_HEADERS, SMTP_POST_HEADERS} from "@/config/axios_config";

let BASE_API_URL;
let SMTP_BASE_API_URL;
const form = new Form();


    BASE_API_URL = process.env.VUE_APP_API_BASE_URL;
    SMTP_BASE_API_URL = process.env.VUE_APP_SMTP_API_BASE_URL


export async function generateNumber(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/numbers/generate/mess', POST_HEADERS)
}

export async function sendSms(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/numbers/send_sms', POST_HEADERS)
}

export async function sendSms_v1(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/numbers/send_sms/vonage', POST_HEADERS)
}


export async function sendSms_v2(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/numbers/send_sms/buka', POST_HEADERS)
}

export async function sendWithSmtp(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(SMTP_BASE_API_URL + '/numbers/smtp/send', SMTP_POST_HEADERS)
}

export async function addSmtp(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/numbers/smtp/add', POST_HEADERS)
}

export async function getCurrentSmtp() {
    return await axios.get(BASE_API_URL + '/number/smtp', GET_HEADERS);
}

export async function getUserSmtpSubscription() {
    return await axios.get(BASE_API_URL + '/number/smtp/user_subscriptions', GET_HEADERS);
}

export async function activateSmtp(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/user/fund/activate_smtp', SMTP_POST_HEADERS);
}

// export async function logoutService() {
//     return await axios.get(BASE_API_URL + '/api_logout', GET_HEADERS);
// }