<template>
  <div  class="app-header header-shadow bg-slick-carbon header-text-light">
    <div class="app-header__logo">

      <!--      <div class="logo-src"></div>-->
      <router-link to="/" style="font-size: 20px !important; margin-right: 14px;">
        <img src="../../assets/logo/logo.png" alt="" width="100"
             height="70">
<!--        <img src="../../assets/logo/test.png" alt="" width="100"-->
<!--             height="70" style="width: 100% !important;">-->
      </router-link>


      <div class="header__pane ml-auto">
        <div>
          <button type="button" class="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                            <span class="hamburger-box">
                                <span class="hamburger-inner"></span>
                            </span>
          </button>
        </div>
      </div>
    </div>
    <div class="app-header__mobile-menu">
      <div>
        <button id="oremi" ref="secondAppContainer" type="button" class="hamburger hamburger--elastic mobile-toggle-nav">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
        </button>
      </div>
    </div>
    <div class="app-header__menu">
      <div class="app-header-right">
        <div class="header-dots">
          <div class="dropdown">
            <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown"
                    class="p-0 mr-2 btn btn-link">
                                            <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                                                <span class="icon-wrapper-bg bg-danger"></span>
                                                <i class="icon text-danger icon-anim-pulse ion-android-notifications"></i>
                                                <span v-if="state.notificationInfo.count > 0"
                                                      class="badge badge-dot badge-dot-sm badge-danger">Notifications</span>
                                            </span>
            </button>
            <div tabindex="-1" role="menu" aria-hidden="true"
                 class="dropdown-menu-xl rm-pointers dropdown-menu dropdown-menu-right">
              <div class="dropdown-menu-header mb-0">
                <div class="dropdown-menu-header-inner theme_bg_solid">
                  <div class="menu-header-image opacity-1"></div>
                  <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">Notifications</h5>
                    <h6 class="menu-header-subtitle">You have <b>{{ state.notificationInfo.count }}</b> unread messages</h6>
                  </div>
                </div>
              </div>

              <div class="tab-content">
                <div class="container mt-2 mb-2">
                  <md-card md-with-hover class="round-card mb-2 mt-2"
                           v-for="notification in state.notificationInfo.notifications" :key="notification.id">
                    <md-ripple>
                      <md-card-content>
                        <div>
                          <b class="text-capitalize">
                            {{ notification.user }}
                          </b> {{ notification.message }}
                        </div>

                        <div class="row mt-2">
                          <div>
                            <md-button @click="ReadNotification(notification.id)" class="md-raised md-dense md-primary"
                                       v-if="notification.read_at == null"> Mark as Read
                            </md-button>
                          </div>

                          <div class="ml-auto mr-3">
                            <span style="vertical-align: middle;">  {{
                                notification.created_at | short_datetime
                              }}</span>
                          </div>
                        </div>

                      </md-card-content>

                    </md-ripple>
                  </md-card>

                  <div class="col-md-12 mt-4">
                    <pagination
                        align="center"
                        :data="pagination"
                        @pagination-change-page="getNotifications"
                        page="1"
                    ></pagination>
                  </div>
                </div>

                <div>
                  <div class="text-center">
                    <md-button v-if="state.notificationInfo.count > 0" @click="ReadAllNotification()"
                               class="md-raised md-dense md-primary"> Mark all As Read
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="app-header__content">
      <div class="app-header-left">


      </div>
      <div class="app-header-right">
        <div class="header-dots">
          <div class="dropdown">
            <button type="button" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown"
                    class="p-0 mr-2 btn btn-link">
                                            <span class="icon-wrapper icon-wrapper-alt rounded-circle">
                                                <span class="icon-wrapper-bg bg-danger"></span>
                                                <i class="icon text-danger icon-anim-pulse ion-android-notifications"></i>
                                                <span v-if="state.notificationInfo.count > 0"
                                                      class="badge badge-dot badge-dot-sm badge-danger">Notifications</span>
                                            </span>
            </button>
            <div tabindex="-1" role="menu" aria-hidden="true"
                 class="dropdown-menu-xl rm-pointers dropdown-menu dropdown-menu-right">
              <div class="dropdown-menu-header mb-0">
                <div class="dropdown-menu-header-inner theme_bg_solid">
                  <div class="menu-header-image opacity-1"></div>
                  <div class="menu-header-content text-dark">
                    <h5 class="menu-header-title">Notifications</h5>
                    <h6 class="menu-header-subtitle">You have <b>{{ state.notificationInfo.count }}</b> unread messages</h6>
                  </div>
                </div>
              </div>

              <div class="tab-content">
                <div class="container mt-2 mb-2">
                  <md-card md-with-hover class="round-card mb-2 mt-2"
                           v-for="notification in state.notificationInfo.notifications" :key="notification.id">
                    <md-ripple>
                      <md-card-content>
                        <div>
                          <b class="text-capitalize">
                            {{ notification.user }}
                          </b> {{ notification.message }}
                        </div>

                        <div class="row mt-2">
                          <div>
                            <md-button @click="ReadNotification(notification.id)" class="md-raised md-dense md-primary"
                                       v-if="notification.read_at == null"> Mark as Read
                            </md-button>
                          </div>

                          <div class="ml-auto mr-3">
                            <span style="vertical-align: middle;">  {{
                                notification.created_at | short_datetime
                              }}</span>
                          </div>
                        </div>

                      </md-card-content>

                    </md-ripple>
                  </md-card>

                  <div class="col-md-12 mt-4">
                    <pagination
                        align="center"
                        :data="pagination"
                        @pagination-change-page="getNotifications"
                        page="1"
                    ></pagination>
                  </div>
                </div>

                <div>
                  <div class="text-center">
                    <md-button v-if="state.notificationInfo.count > 0" @click="ReadAllNotification()"
                               class="md-raised md-dense md-primary"> Mark all As Read
                    </md-button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <div class="header-btn-lg pr-0">
          <div class="widget-content p-0">
            <div class="widget-content-wrapper">
              <div class="widget-content-left">
                <div class="btn-group">
                  <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="p-0 btn">
                    <img v-if="state.userInfo.image == null" width="42" class="rounded-circle"
                         src="/assets/images/avatars/1.jpg" alt="">
                    <img v-if="state.userInfo.image != null" width="42" class="rounded-circle"
                         :src="getProfileImage(state.userInfo.image)" alt="">
                    <i class="fa fa-angle-down ml-2 opacity-8"></i>
                  </a>
                  <div tabindex="-1" role="menu" aria-hidden="true"
                       class="rm-pointers dropdown-menu-lg dropdown-menu dropdown-menu-right">
                    <div class="dropdown-menu-header">
                      <div class="dropdown-menu-header-inner bg-info">
                        <div class="menu-header-image opacity-2"
                             style="background-image: url('/assets/images/dropdown-header/city3.jpg');">
                        </div>
                        <div class="menu-header-content text-left">
                          <div class="widget-content p-0">
                            <div class="widget-content-wrapper">
                              <div class="widget-content-left mr-3">
                                <img v-if="state.userInfo.image == null" width="42" class="rounded-circle"
                                     src="/assets/images/avatars/1.jpg" alt="">
                                <img v-if="state.userInfo.image != null" width="42" class="rounded-circle"
                                     :src="getProfileImage(state.userInfo.image)" alt="">
                              </div>
                              <div class="widget-content-left">
                                <div class="widget-heading">{{ state.userInfo.first_name }}
                                  {{ state.userInfo.last_name }}
                                </div>
                                <!--                                <div class="widget-subheading opacity-8">A short profile description</div>-->
                              </div>
                              <div class="widget-content-right mr-2">
                                <button class="btn-pill btn-shadow btn-shine btn btn-focus" @click="logout">Logout
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>


                    <div class="grid-menu grid-menu-2col">
                      <div class="no-gutters row">
                        <div class="col-sm-12">

                          <router-link to="/account-settings">
                            <button
                                class="btn-icon-vertical btn-transition btn-transition-alt pt-2 pb-2 btn btn-outline-dark">
                              <i class="pe-7s-users icon-gradient bg-amy-crisp btn-icon-wrapper mb-2"></i> My Account
                            </button>
                          </router-link>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="widget-content-left  ml-3 header-user-info">
                <div class="widget-heading text-capitalize">{{ state.userInfo.username }}</div>
<!--                <div class="widget-subheading text-capitalize">{{state.userInfo.type}}</div>-->
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {logoutService} from '@/services/user/userService'
import {getNotification, readNotification, readAllNotification} from "@/services/notificationService";

export default {
  name: "app-header",
  data() {
    return {
      pagination: {},
     state: {
        userInfo: null,
       notificationInfo: null
     }
    }
  },

  methods: {
    logout() {
      logoutService().then(() => {

        store.dispatch({
          type: 'updateUser',
          data: false
        })

        setTimeout(() => {
          window.location.href = "/"
        }, 800);

      }).catch(() => {

      })
    },

    prepPagination(data) {
      this.pagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    getNotifications(page = 1) {
      getNotification(page)
          .then( response => {
            console.log(response.data)
            store.dispatch({
              type: 'updateNotification',
              data: response.data ?? null
            })
            this.prepPagination(response.data.notifications.original);
            this.state.notificationInfo = store.getters.notification
          })
          .catch(error => {
            console.log(",error..", error)
          })
    },

    getProfileImage(image) {
      return this.$BASE_ASSETS_URL + 'profile_picture/' + image;
    },

    ReadNotification(id) {
      readNotification(id)
          .then(response => {
            this.getNotifications();
            this.$notify({
              message: response.data.message,
              type: "success",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          }).catch(error => {
        this.$notify({
          message: error.response.data.message,
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      })
    },

    ReadAllNotification() {
      readAllNotification().then(response => {
        this.getNotifications();
        this.$notify({
          message: response.data.message,
          type: "success",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      }).catch()
    },

    removeSidebarMobileClass() {
      // alert('kook');
      const secondAppContainer = this.$refs.secondAppContainer;
      // console.log(secondAppContainer)
      if (secondAppContainer.classList.contains('is-active')) {
        secondAppContainer.classList.remove('is-active');
      }
    }
  },

  mounted() {
    this.getProfileImage();
    this.getNotifications();
    this.$on('remove-sidebar-mobile-class', this.removeSidebarMobileClass);
  },

  created() {
    this.state.userInfo = store.getters.user
    this.state. notificationInfo = store.getters.notification
  }
}
</script>

<style>
@media (max-width: 991.98px) {
  .dropdown-menu.dropdown-menu-right {
    top: 125px !important;
  }
}

.theme_bg_solid{
  background: #353535 ;
  color: white !important;
}

.menu-header-content{
  color: white !important;
}


</style>