/* eslint-disable */
// noinspection DuplicatedCode

import {Form} from 'vform'
import axios from "axios";
import {GET_HEADERS, POST_HEADERS} from "@/config/axios_config";

let BASE_API_URL;
const form = new Form();


    BASE_API_URL = process.env.VUE_APP_API_BASE_URL;


export async function loadTransactions(page,type) {
    return await axios.get(BASE_API_URL + `/user/transactions/${type}` + "?page=" + page, GET_HEADERS);
}

export async function approveTransactions(id,type) {
    return await axios.get(BASE_API_URL + `/user/transactions/${type}/approve/` + id, GET_HEADERS);
}

export async function declineTransactions(id,type) {
    return await axios.get(BASE_API_URL + `/user/transactions/${type}/decline/` + id, GET_HEADERS);
}

export async function loadSmtpTransactions(page) {
    return await axios.get(BASE_API_URL + `/number/smtp/payment` + "?page=" + page, GET_HEADERS);
}

export async function approveSmtpTransactions(id) {
    return await axios.get(BASE_API_URL + `/number/smtp/payment/approve/` + id, GET_HEADERS);
}

export async function declineSmtpTransactions(id) {
    return await axios.get(BASE_API_URL + `/number/smtp/payment/decline/` + id, GET_HEADERS);
}


