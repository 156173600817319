<script>
import image from '@/assets/EmptyInbox.png'
export default {
  name: "noDataComponent",
  data() {
    return {
      imagePath: image,
    };
  },
}
</script>

<template>
  <div  style="display: grid; place-items: center">
    <img  class="m-auto " :src="imagePath" alt="Image">
    <span class="">No data</span>
  </div>
</template>

<style scoped>

</style>