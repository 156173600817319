import Vue from 'vue'
import App from './App.vue'
import router from './router'


Vue.config.productionTip = false

import VueJsonToCsv from 'vue-json-to-csv'

Vue.use(VueJsonToCsv);


//v-form
import {Form} from 'vform'

window.Form = Form;


import {
    Button,
    HasError,
    AlertError,
    AlertErrors,
    AlertSuccess
} from 'vform/src/components/bootstrap5'


Vue.component(Button.name, Button)
Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)
Vue.component(AlertErrors.name, AlertErrors)
Vue.component(AlertSuccess.name, AlertSuccess)


//vue material
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

Vue.use(VueMaterial)


import VueSlickCarousel from 'vue-slick-carousel'
// optional style for arrows & dots
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'

Vue.use(VueSlickCarousel)



//sweet alert
import Swal from 'sweetalert2'

window.Swal = Swal;

//sweet alert toaster
const Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: false,
    didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
})
window.Toast = Toast;

//Moment
import moment from 'moment';

Vue.filter(
    'datetime',
    function (value) {
        return moment(value).format('MMMM Do YYYY, h:mm:ss a');
    }
);

Vue.filter(
    'short_datetime',
    function (value) {
        return moment(value).format('MMMM Do YYYY');
    }
);

Vue.filter(
    'time',
    function (value) {
        return moment(value).format('h:mm:ss a');
    }
);

Vue.filter(
    'myTime',
    function (value) {
        return moment(value).format('h:mm:ss a');
    }
);


Vue.filter(
    'date',
    function (value) {
        return moment(value).format('MMMM Do YYYY');
    }
);

Vue.filter(
    'fromNow',
    function (value) {
        return moment(value).fromNow();
    }
);

// vue loading overlay
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading, {
    loader: 'dots',
    height: 164,
    width: 164,
    color: '#000000',

});

// vue progress bar
import VueProgressBar from 'vue-progressbar'

Vue.use(VueProgressBar, {
    color: 'rgb(143, 255, 199)',
    failedColor: 'red',
    height: '12px'
});

import AOS from 'aos';
import 'aos/dist/aos.css'; // You can also use <link> for styles
// ..
AOS.init();

// notify
import VueNotification from '@mathieustan/vue-notification';

Vue.use(VueNotification, {
    theme: {
        colors: {
            success: '#3ac47d',
            darkenSuccess: '#2f7c39',
            info: '#16aaff',
            dark: '#343a40',
            warning: '#f7b924',
            darkenWarning: '#f69a07',
            error: '#d92550',
            darkenError: '#ff245f',
            offline: '#ff4577',
            darkenOffline: '#ff245f',
        },
        boxShadow: '0 0.125rem 0.625rem black',
    },
});

// //vue bootstrap
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
//
// // Import Bootstrap and BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
// import 'bootstrap-vue/dist/bootstrap-vue.css'
//
// // Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
// // Optionally install the BootstrapVue icon components plugin
// Vue.use(IconsPlugin)

// Dialog
import VModal from 'vue-js-modal'

Vue.use(VModal)

//events handling
window.Fire = new Vue();

import axios from 'axios';

Vue.prototype.$http = axios;

import store from "@/store";

Vue.prototype.$STORE = store;
Vue.prototype.$HEADERS = {
    headers: {
        "Authorization": 'Bearer ' + store.getters.isAuthenticated,
    }
};

Vue.prototype.$POST_HEADERS = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.getters.isAuthenticated
    }
};


// if (process.env.NODE_ENV === "development") {
//     Vue.prototype.$BASE_API_URL = '/api/v1';
//     Vue.prototype.$BASE_ASSETS_URL = 'http://127.0.0.1:8000/';
//
// } else if (process.env.NODE_ENV === "production") {
    Vue.prototype.$BASE_API_URL = process.env.VUE_APP_API_BASE_URL;
    Vue.prototype.$BASE_ASSETS_URL = process.env.VUE_APP_API_ASSET_BASE_URL;
// }

Vue.component('pagination', require('laravel-vue-pagination'));

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
