<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <i class="pe-7s-home icon-gradient bg-mean-fruit"></i>
          </div>
          <div class="text-black">{{ getTime() }} {{ $STORE.state.user.username }}
            <div class="page-title-subheading">Welcome to your dashboard
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tabs-animation">
      <div class="row mb-5">

        <div class=" mb-3 col-md-6 opacity-50">
          <md-card md-with-hover class="round-card">
            <md-ripple>
              <md-card-content>
                <div>
                  <h6 v-if="$STORE.state.user.type == 'admin'" class=""> Credit Balance : ${{ buka.balance }} </h6>
                  <h6 v-if="$STORE.state.user.type == 'user'" class=""> Credit Balance : ${{ balance.toFixed(2)}} </h6>
                </div>
              </md-card-content>
            </md-ripple>
          </md-card>
        </div>

        <div class=" col-md-6 opacity-50" v-if="$STORE.state.user.type == 'admin'">
          <md-card md-with-hover class="round-card">
            <md-ripple>
              <md-card-content>
                <div>
                  <h6 class=""> Number Generator Credit Balance : ${{ vonage.amount  }} </h6>
                </div>
              </md-card-content>
            </md-ripple>
          </md-card>
        </div>


      </div>
    </div>
  </div>
</template>
<style scoped>

</style>
<script>

import {getUserBalancev1} from "@/services/user/userService";

export default {
  name: 'dashboard',
  components: {},
  data() {
    return {
      // eslint-disable-next-line no-undef
      // form: new Form({
      //   numbers: "",
      //   message_body: "",
      // }),
      submitting: false,
      buka: 0,
      balance: 0,
      vonage: 0,

    }
  },

  methods: {

    getTime() {
      const d = new Date();
      const time = d.getHours();

      if (time < 12) {
        return "Good Morning";
      } else if (time >= 12 && time <= 17) {
        return "Good Afternoon";
      } else if (time >= 17 && time <= 24) {
        return "Good Evening";
      }
    },


    getBalanceOn_buka() {
      this.$Progress.start();
      // let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        getUserBalancev1()
            .then(response => {
              // loader.hide();
              // this.convert(response.data.data);
              this.buka = response.data.data;
              this.balance = response.data.data;

              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })

            })
            .catch(error => {
              console.log(error.response);
              // loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 1000)
    },

  },

  created() {
    this.getBalanceOn_buka();
  },

  mounted() {

  }
}
</script>
