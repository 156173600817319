<template>
    <div class="app-main__inner">
      <div class="app-page-title">
        <div class="page-title-wrapper">
          <div class="page-title-heading">
            <div class="page-title-icon">
                <md-icon class="metismenu-icon">shopping_cart</md-icon>
            </div>
            <div class="text-black">ORDERS
              <div class="page-title-subheading">Review customers orders and proceed to purchase.
              </div>
            </div>
          </div>
        </div>
      </div>
      <md-card md-with-hover class="round-card mb-5">
      <md-ripple>
        <md-card-header>
          <h3>ORDERS</h3>
        </md-card-header>

        <md-card-content>
          Lorem ipsum dolor sit amet, consectetur adipisicing elit. Optio itaque ea, nostrum odio. Dolores, sed accusantium quasi non.
        </md-card-content>

        <md-card-actions>
          <md-button>Action</md-button>
          <md-button>Action</md-button>
        </md-card-actions>
      </md-ripple>
    </md-card>
    </div>
</template>
<script>
export default {
  name: "ordersComponent",
}
</script>