<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">ondemand_video</md-icon>
          </div>
          <div>User Messaging
            <div class="page-title-subheading">Broadcast a general Message to all Users
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Table Code Start here-->
    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5">
          <md-ripple>
            <md-card-header>
              <div class="md-title">Compose a Message to Broadcast to all Users</div>
            </md-card-header>
            <md-card-content>
              <div class="mt-5 mb-5">
                <form @submit.prevent="upload()">
                  <div class="container">
                    <div class="row">
                      <div class="col-md-12">
                        <md-field>
                          <md-icon>description</md-icon>
                          <label>Message</label>
                          <md-textarea v-model="form.message"></md-textarea>
                        </md-field>
                      </div>
                    </div>

                    <div class="float-right">
                      <md-button type="submit" class="md-raised md-dense md-primary">BROADCAST</md-button>
                    </div>
                  </div>
                </form>
              </div>
            </md-card-content>
          </md-ripple>
        </md-card>

      </div>
    </div>


  </div>
</template>

<script>

import {userMessageBroadcast} from "@/services/settings/settingService";

export default {
  name: "UserMessaging",

  data() {
    return {
      submitting: false,
      // eslint-disable-next-line no-undef
      form: {
        message: "",
      },
    };
  },

  methods: {
    //
    // getValidationClass(fieldName) {
    //   const field = this.form.errors.has(fieldName);
    //   if (field) {
    //     return "md-invalid";
    //   }
    // },

    upload() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        userMessageBroadcast(this.form)
            .then((response) => {
              loader.hide();
              this.$Progress.finish();
              this.submitting = false;
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              });
            })
            .catch((error) => {
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                this.form.errors.set(error.response.data.error);
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 2000)
    },

  },

  created() {
  }
}
</script>

<style scoped>

</style>