/* eslint-disable */
// noinspection DuplicatedCode

import {Form} from 'vform'
import axios from "axios";
import store from "@/store";

let BASE_API_URL;
const form = new Form();

const GET_HEADERS = {
    headers: {
        "Authorization": 'Bearer ' + store.getters.isAuthenticated,
    }
};

const POST_HEADERS = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.getters.isAuthenticated
    }
};



    BASE_API_URL = process.env.VUE_APP_API_BASE_URL;




export async function getNotification(page) {
    return await axios.get(BASE_API_URL + '/notifications/get' + "?page=" + page , GET_HEADERS);
}

export async function readNotification(id) {
    return await axios.get(BASE_API_URL + '/notifications/read/' + id , GET_HEADERS);
}
export async function readAllNotification() {
    return await axios.get(BASE_API_URL + '/notifications/readAll' , GET_HEADERS);
}