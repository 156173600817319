<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper justify-content-between">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <md-icon class="metismenu-icon">shopping_cart</md-icon>
          </div>
          <div class="text-black nav_instructions">
            Minimart
            <div class="page-title-subheading">Delivery across the world.
            </div>
          </div>
          <div class="mobile_navbar_container">
            <div class="navbarContent">
              <div @click="getProductsByCategory('all')">All</div>
              <div class="text-capitalize" @click="getProductsByCategory(data.id)" v-for="data in categorylist" :key="data.id">{{ data.name }}</div>
            </div>
          </div>
        </div>

        <div class="main_box_cont" style="display: flex; justify-content: flex-end;">
          <md-card :class="onview2()" class="round-card main_box_cont_mini">
            <md-ripple>
              <md-card-content>
                <div class="mobile_search_cont">
                  <md-button @click="onClickView" class="md-icon-button md-raised">
                    <md-icon v-if="!showSearch">search</md-icon>
                    <md-icon v-if="showSearch">close</md-icon>
                  </md-button>
                </div>
                <form :class="onView()" class="searchbarContainer" @submit.prevent="">
                <div class="topSect">
                  <div class="topSect_top">
                    <md-field md-clearable>
                      <label>Search</label>
                      <md-input v-model="searchKeyword"></md-input>
                    </md-field>
                  </div>
                  <div class="topSect_top">
                    <md-field>
                      <md-select v-model="search_location" name="country" id="country" placeholder="Location">
                        <md-option value="international">International (Wholesale)</md-option>
                        <md-option value="nigeria">Nigeria</md-option>
                        <md-option value="usa">USA</md-option>
                      </md-select>
                    </md-field>
                  </div>
                  <div class="topSect_top">
                    <md-button type="submit" @click="debounceSearch" class="md-icon-button md-raised">
                      <md-icon>search</md-icon>
                    </md-button>
                  </div>
                </div>
                </form>
              </md-card-content>
            </md-ripple>
          </md-card>
        </div>

      </div>
    </div>


    <md-card class="round-card md-elevation-7 remove-bg mb-5">
      <md-card-content>
        <div class="col-md-12">
          <productCardComponent
              :data=products
          />
        </div>
        <div class="col-md-12 mb-3 mt-3">
          <div class="justify-content-center" style="display: flex !important;">
            <div>
              <pagination
                  :data="pagination"
                  @pagination-change-page="getProducts"
                  :limit="5"
                  page="1"
              ></pagination>
            </div>
          </div>
        </div>
        <div class="floatingCart">
          <md-badge :md-content="$STORE.state.cartCount">
            <md-button class="md-icon-button" @click="openCart">
              <md-icon>shopping_cart</md-icon>
            </md-button>
          </md-badge>
        </div>
      </md-card-content>
    </md-card>

    <cart-component
        :show="showDialog"
        title="Cart"
        @update:showCart="updateModalShow"
        :data="cartProd"
        :raw="rawPagination"
        :load-cart="loadCart"
        :remove-product="confirmRemoveProduct"
        :update-quantity="UpdateQuantity"
    ></cart-component>
  </div>
</template>


<script>
import {getProducts, searchProducts} from "@/services/market/marketService";
import productCardComponent from "@/components/utils/productCardComponent";
import cartComponent from "./cartComponent.vue";
import {
  getCartCount, getProductsByCategory,
  loadCart,
  removeProductCart,
  updateCartProdQuantity
} from "../../../services/market/marketService";
import store from "@/store";
import {loadCategories} from "../../../services/settings/settingService";

export default {
  name: "miniMartComponent",
  components: {
    productCardComponent,
    cartComponent
  },
  data() {
    return {
      products: [],
      cartProd: [],
      filter: "All",
      pagination: {},
      rawPagination: {},
      submitting: false,
      useSearch: null,
      showDialog: false,
      showSearch: false,
      searchKeyword: '',
      search_location: 'international',
      categorylist:[]
    }
  },
  methods: {
    onClickView(){
      this.showSearch = !this.showSearch;
    },
    onView(){
      if (this.showSearch){
        return 'onViewSearch';
      }

    },
    onview2(){
      if (this.showSearch){
        return 'onViewSearch2';
      }

    },
    prepPagination(data) {
      this.pagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    prepPagination2(data) {
      this.rawPagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    getProducts(page = 1) {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        getProducts(page)
            .then((response) => {
              loader.hide();
              this.$Progress.finish();
              this.prepPagination(response.data);
              this.products = response.data.data;
            })
            .catch(error => {
              this.$notify({
                message: error.response.data.message,
                type: "error",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 4500,
                offset: 50,
              });
            })
      }, 1000)
    },

    SearchProduct(page, data, data2) {
      searchProducts(page, data, data2)
          .then((response) => {
            this.prepPagination(response.data);
            this.products = response.data.data;
          })
          .catch(error => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    debounceSearch() {
      if (this.searchKeyword == '') {
        this.getProducts();
      } else {
        this.SearchProduct(1, this.searchKeyword, this.search_location);
      }
    },

    getCartCount() {
      getCartCount()
          .then((response) => {
            store.dispatch({
              type: 'updateCartCount',
              data: response.data.data
            })
          })
          .catch(error => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    openCart() {
      this.loadCart()
      this.showDialog = true
    },

    updateModalShow(value) {
      this.showDialog = value;
    },

    loadCart(page = 1) {
      loadCart(page)
          .then((response) => {
            this.cartProd = response.data.data;
            this.prepPagination2(response.data);
          })
    },

    confirmRemoveProduct(id) {
      // eslint-disable-next-line no-undef
      Swal.fire({
        title: 'Remove Product From Cart ?',
        text: "Do you want to remove this Product from your Cart",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Yes, Remove Product'
      }).then((result) => {
        if (result.isConfirmed) {
          this.doRemoveProduct(id)
        }
      })
    },

    doRemoveProduct(id) {
      removeProductCart(id)
          .then(() => {
            this.loadCart();
            this.getCartCount();
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    UpdateQuantity(id, amt, mode) {
      updateCartProdQuantity(id, amt, mode)
          .then(() => {
            this.loadCart()
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    getCategories() {
      loadCategories()
          .then((response) => {
            this.categorylist = response.data.data;
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    getProductsByCategory(id){
      getProductsByCategory(id)
          .then((response) => {
            this.prepPagination(response.data);
            this.products = response.data.data;
          })
          .catch()
    }

  },

  mounted() {
    this.loadCart()
  },

  created() {
    this.getProducts();
    this.getCartCount();
    this.loadCart();
    this.getCategories();
  }
}
</script>

<style scoped>
.remove-bg {
  background: unset !important;
}

.floatingCart {
  position: fixed;
  bottom: 10px;
  right: 10px;
  margin-right: 10px;
  background: #FFFFFF;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
}

.topSect {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.topSect_top {
  max-width: 300px;
}
.mobile_navbar_container{
  display: none;
}

.nav_instructions{
  display: block;
}
.searchbarContainer{
  display: block;
}

.mobile_search_cont{
  display: none;
}


@media (max-width: 600px) {
  .topSect_top {
    width: 100%;
  }

  .searchbarContainer{
    display: none ;
  }

  .onViewSearch{
    display: block !important;
  }

  .main_box_cont_mini{
    background: unset !important;
    box-shadow: unset!important;
  }
  .onViewSearch2{
    background-color: var(--md-theme-default-background, #fff) !important;
    color: var(--md-theme-default-text-primary-on-background, rgba(0, 0, 0, 0.87)) !important;
  }

  .mobile_search_cont{
    justify-content: flex-end;
    display: flex;
  }
  .topSect {
    gap: 0;
  }

  .mobile_navbar_container{
    display: block !important;
  }
}

@media (max-width: 991px) {
  .mobile_navbar_container{
    display: block !important;
    width: 100%;
    overflow: hidden;
    height: 30px;
    margin-top: 20px;
  }

  .navbarContent{
    overflow-x: auto;
    white-space: nowrap;
    gap: 10px;
    display: flex;
    height: 35px;
  }

  .nav_instructions{
    display: none;
  }
}

@media (min-width: 601px) and (max-width: 991px) {
  .main_box_cont{
    margin-top: 20px;
    justify-content: center !important;
  }
}

.navbarContent div{
  color: black !important;
}


</style>