<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">inventory</md-icon>
          </div>
          <div class="text-black">View All Products
            <div class="page-title-subheading">
              View and Manage all Available Products
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>
            <md-card-content class="container">
              <div class="">
                <div class="mt-5">
                  <md-table class="larg_table">
                    <md-table-row>
                      <md-table-head class="text-center" md-numeric>ID</md-table-head>
                      <md-table-head class="text-center">Name</md-table-head>
                      <md-table-head class="text-center">Image</md-table-head>
                      <md-table-head class="text-center">Stock</md-table-head>
                      <md-table-head class="text-center">Price</md-table-head>
                      <md-table-head class="text-center">Options Tag</md-table-head>
                      <md-table-head class="text-center">Options</md-table-head>
                      <md-table-head class="text-center">Description</md-table-head>
                      <md-table-head class="text-center">Description Images</md-table-head>
                      <md-table-head class="text-center">Created At</md-table-head>
                      <md-table-head class="text-center">Status</md-table-head>
                      <md-table-head v-if="$STORE.state.user.type == 'admin'" class="text-center">Action
                      </md-table-head>
                    </md-table-row>
                    <md-table-row v-for="row in products" :key="row.id">
                      <md-table-cell class="text-center" md-numeric> {{ row.id }}</md-table-cell>
                      <md-table-cell class="text-center">{{ row.name }}</md-table-cell>
                      <md-table-cell class="text-center">
                        <tableImageViewerComponent
                            title="Product Image"
                            :images="row.image"
                        />
                      </md-table-cell>
                      <md-table-cell class="text-center">{{ row.stock }}</md-table-cell>
                      <md-table-cell class="text-center">{{ row.price }}</md-table-cell>
                      <md-table-cell class="text-center">{{ row.options_tag }}</md-table-cell>
                      <md-table-cell class="text-center">{{ row.options }}</md-table-cell>
                      <md-table-cell class="text-center">
                        <div class="desc_box">
                          <tableImageViewerComponent
                              title="Product Description"
                              :text="row.description"
                          />
                        </div>
                      </md-table-cell>
                      <md-table-cell class="text-center">
                        <tableImageViewerComponent
                            title="Product Description Images"
                            :images="row.description_images"
                        />
                      </md-table-cell>
                      <md-table-cell class="text-center">{{ row.created_at | datetime }}</md-table-cell>
                      <md-table-cell class="text-center">
                        <md-chip :class="getStatusClass(row.status)">{{ getStatusText(row.status) }}</md-chip>
                      </md-table-cell>
                      <md-table-cell v-if="$STORE.state.user.type == 'admin'" class="text-center">
                        <div
                            class="btn-group"
                            role="group"
                            aria-label="Basic example"
                        >

                          <md-button
                              v-if="row.status === '0' || row.status === '2'  "
                              class="md-fab md-mini bg-success"
                              @click="changeStatus(row.id, '1')"

                          >
                            <md-icon>check_circle</md-icon>
                            <md-tooltip md-direction="bottom"
                            >Activate Product
                            </md-tooltip
                            >
                          </md-button>

                          <md-button
                              v-if="row.status === '1' "
                              class="md-fab md-mini bg-danger"
                              @click="changeStatus(row.id, '0')"

                          >
                            <md-icon>close</md-icon>
                            <md-tooltip md-direction="bottom"
                            >Deactivate Product
                            </md-tooltip
                            >
                          </md-button>

                          <md-button
                              class="md-fab md-mini md-accent"
                              @click="deleteProduct(row.id)"
                          >
                            <md-icon>delete</md-icon>
                            <md-tooltip md-direction="bottom"
                            >Delete Product
                            </md-tooltip
                            >
                          </md-button>

                        </div>
                      </md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>

              </div>

            </md-card-content>

            <div class="col-md-12 mb-3 mt-3">
              <div class="justify-content-center" style="display: flex !important;">
                <div>
                  <pagination
                      :data="pagination"
                      @pagination-change-page="loadProducts"
                      :limit="5"
                      page="1"
                  ></pagination>
                </div>

              </div>
            </div>

          </md-ripple>
        </md-card>
      </div>


    </div>


  </div>
</template>

<script>
import {changeProductStatus, deleteProduct, loadProducts} from "@/services/market/marketService";
import tableImageViewerComponent from "@/components/utils/tableImageViewerComponent";

export default {
  name: "viewProductsComponent",
  components: {
    tableImageViewerComponent
  },
  data() {
    return {
      products: [],
      filter: "All",
      pagination: {},
      submitting: false,
    };
  },
  methods: {
    getStatusClass(status) {
      if (status == '1') { //active
        return "bg-success";
      } else if (status == "0") { //de-active
        return "md-primary";
      } else if (status == "2") { //deleted
        return "md-accent";
      }
    },

    getStatusText(status) {
      if (status == '1') { //active
        return "Active";
      } else if (status == "0") { //unactive
        return "Pending";
      } 
    },

    prepPagination(data) {
      this.pagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    loadProducts(page = 1) {

      loadProducts(page)
          .then((response) => {
            this.prepPagination(response.data);
            this.products = response.data.data;
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    changeStatus(id, type) {
    changeProductStatus(id, type).then( () => {
      this.loadProducts()
    })
    },

    deleteProduct(id) {
      deleteProduct(id).then( () => {
      this.loadProducts()
    })
    }
  },

  created() {
    this.loadProducts();
  },

  mounted() {

  }
}
</script>

<style scoped>
/*@media (min-width: 1700px){*/
/*  .container {*/
/*    max-width: 100% !important;*/
/*  }*/
/*}*/
@media (min-width: 1300px){
  .container {
    max-width: 100% !important;
  }
}

.desc_box{
  height: fit-content;
}
</style>