/* eslint-disable */
// noinspection DuplicatedCode

import {Form} from 'vform'
import axios from "axios";
import {GET_HEADERS, POST_HEADERS} from "@/config/axios_config";

let BASE_API_URL;
let SMTP_BASE_API_URL;
const form = new Form();


    BASE_API_URL = process.env.VUE_APP_API_BASE_URL;
    SMTP_BASE_API_URL = process.env.VUE_APP_SMTP_API_BASE_URL



export async function loginService(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/auth/login')
}

export async function registerService(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/auth/register')
}

export async function loginSMTPService(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(SMTP_BASE_API_URL + '/auth/login')
}

export async function logoutService() {
    return await axios.get(BASE_API_URL + '/api_logout', GET_HEADERS);
}

export async function createUser(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/user/add', POST_HEADERS)
}

export async function loadUsers(page) {
    return await axios.get(BASE_API_URL + '/user' + "?page=" + page, GET_HEADERS);
}

export async function changeUserStatus(id, status) {
    return await axios.get(BASE_API_URL + '/user_change_status/' + id + '/' + status, GET_HEADERS);
}

export async function getUserBalancev1() {
    return await axios.get(BASE_API_URL + '/numbers/send_sms/getbalance/v1', GET_HEADERS);
}

