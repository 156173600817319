<template>
 <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">card_giftcard</md-icon>
          </div>
          <div>Trade Gift Card
            <div class="page-title-subheading">
              Trade Gift card, redeem to crypto wallet or use to spam
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5">
          <md-ripple>

            <md-card-content>
              <div class="col-md-4">
                <h4>Your Wallet</h4>
              <div class="heading">
                <p>Amount of GiftCard traded</p>
                <h1>$0.00000</h1>
              </div>
              </div>
              <div class="col-md-12">
                <h4 class="hot">Hot GiftCard</h4>
                <table>
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Country</th>
                    <th>Price</th>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Amazon</td>
                    <td>USA</td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>2</td>
                    <td>Sephora</td>
                    <td>Canada</td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>3</td>
                    <td>Adbum</td>
                    <td>Germany</td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>1</td>
                    <td>Amazon</td>
                    <td>USA</td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>5</td>
                    <td>Sephora</td>
                    <td>Canada</td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>6</td>
                    <td>Amazon</td>
                    <td>USA</td>
                    <td>--</td>
                  </tr>
                  <tr>
                    <td>7</td>
                    <td>Amazon</td>
                    <td>USA</td>
                    <td>--</td>
                  </tr>
                </table>
              </div>
            </md-card-content>
          </md-ripple>
        </md-card>
      </div>
    </div>
    
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5">
          <md-ripple>
            <md-card-content>
              <h4>My Assets</h4>
              <div class="row">
              <div class="col-md-2">
                <div class="card">
                  <img src="./amazon logo.png" alt="" width="32px">
                  <p><small>Amazon</small></p>
                  <p><b>$55,000.00</b></p>
                  <p><small><span>-1.05%</span></small></p>
                </div>
              </div>
               <div class="col-md-2">
                <div class="card">
                  <img src="./amazon logo.png" alt="" width="32px">
                  <p><small>Amazon</small></p>
                  <p><b>$55,000.00</b></p>
                  <p><small><span>-1.05%</span></small></p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="card">
                  <img src="./amazon logo.png" alt="" width="32px">
                  <p><small>Amazon</small></p>
                  <p><b>$55,000.00</b></p>
                  <p><small><span>-1.05%</span></small></p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="card">
                  <img src="./amazon logo.png" alt="" width="32px">
                  <p><small>Amazon</small></p>
                  <p><b>$55,000.00</b></p>
                  <p><small><span>-1.05%</span></small></p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="card">
                  <img src="./amazon logo.png" alt="" width="32px">
                  <p><small>Amazon</small></p>
                  <p><b>$55,000.00</b></p>
                  <p><small><span>-1.05%</span></small></p>
                </div>
              </div>
              <div class="col-md-2">
                <div class="card">
                  <img src="./amazon logo.png" alt="" width="32px">
                  <p><small>Amazon</small></p>
                  <p><b>$55,000.00</b></p>
                  <p><small><span>-1.05%</span></small></p>
                </div>
              </div>
            </div>
            </md-card-content>
          </md-ripple>
        </md-card>
      </div>
    
  </div>
</template>

<script>
export default {
  name: "giftCardComponent"
}
</script>

<style scoped>
.heading{
  color: white;
  padding: 10px;
  background-image: linear-gradient(to bottom right, #FC6474, #EC4E31, #d17423, #DF6394);
  border-radius: 20px;
}
.hot{
  margin: 50px 0 20px 0;
}
table{
  width: 100%;
  border-collapse: collapse;
  border-radius: 15px;
  overflow: hidden;
}
th, td{
  padding: 10px;
  background:#363636;
  color: white;
  border-bottom: 10px solid white;
}
.card{
  background-image: linear-gradient(to bottom right, #FC6474, #EC4E31, #DF6394);
  padding: 20px;
  border-radius: 20px;
  color: white;
}
p{
  margin: 3px !important;
}
span{
  background-color: white;
  color: #FC6474;
  padding: 3px 5px 3px 5px;
  border-radius: 30px;
}
</style>