<script>
import {getImage} from "@/services/utils/imageViewerService";
import noDataComponent from "../../utils/noDataComponent.vue";
import {
  loadOrder, orderProduct,
  refreshPaymentStatus,
  updateOrderStatus,
  uploadTracking
} from "../../../services/market/marketService";

export default {
  name: "myOrderComponent",
  components: {noDataComponent},
  data() {
    return {
      path: 'images/product_picture/',
      submitting: false,
      filter: "All",
      pagination: {},
      PurchasedLinks: [],
      // eslint-disable-next-line no-undef
      form: {
        link_id: "",
        links: "",
        tracking_number: null,
      },
      spinning: false,
      modal_tracking: '',
      orderbyProduct: [],
      showDialog: false
    };
  },

  methods: {
    getStatusClass(status) {
      if (status == 'pending') {
        return "trans_pending";
      } else if (status == "waiting") {
        return "trans_waiting";
      } else if (status == "confirming") {
        return "trans_confirming";
      } else if (status == "confirmed") {
        return "trans_confirmed";
      } else if (status == "failed" || status == "unpaid") {
        return "trans_failed";
      } else if (status == "refunded") {
        return "trans_refunded";
      } else if (status == "expired") {
        return "trans_expired";
      } else if (status == "finished" || status == "paid") {
        return "trans_finished";
      } else if (status == "partially_paid") {
        return "trans_partial";
      } else if (status == "sending") {
        return "trans_sending";
      } else if (status == "received") {
        return "order_received";
      } else if (status == "packed") {
        return "order_packed";
      } else if (status == "shipped") {
        return "order_shipped";
      } else if (status == "delivered") {
        return "order_delivered";
      } else if (status == "canceled") {
        return "order_canceled";
      }
    },

    openTrackingModal(tracking) {
      if (tracking == '' || tracking == null) {
        this.modal_tracking = '';
      } else {
        this.modal_tracking = tracking;
      }

      this.$modal.show("tracking_modal");
    },

    openProductModalBox() {
      // this.orderbyProduct = 'ore';
      if (this.orderbyProduct.products.length <= 0) {
        return
      }
      this.$modal.show("product_modal");
    },

    openProductModal(id) {
      const newForm = {
        order_id: id,
      }
      // this.showDialog = true;
      orderProduct(newForm).then((res) => {
        this.orderbyProduct = res.data.data;
        console.log(res.data.data)
        this.openProductModalBox(res)
      })
    },

    // getValidationClass(fieldName) {
    //   const field = this.form.errors.has(fieldName);
    //   if (field) {
    //     return "md-invalid";
    //   }
    // },

    prepPagination(data) {
      this.pagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    loadAllOrder(page = 1) {
      loadOrder(page).then((response) => {
        this.prepPagination(response.data);
        this.PurchasedLinks = response.data.data;
      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      })
    },

    refreshPaymentStatus(id) {
      this.spinning = true;
      refreshPaymentStatus(id).then(() => {
        this.spinning = false
        this.loadAllOrder();
      })
    },

    updateStatus(id, status) {
      updateOrderStatus(id, status).then(() => {
        this.loadAllOrder();
      })
    },

    uploadTracking(id) {
      const newForm = {
        order_id: id,
        tracking: this.form.tracking_number,
      }
      uploadTracking(newForm).then(() => {
        this.form.tracking_number = null;
        this.loadAllOrder();
      })
    },

    getImagePath(data) {
      return getImage(this.path, data)
    },

  },

  created() {
    this.loadAllOrder();
  }
}
</script>

<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">local_shipping</md-icon>
          </div>
          <div>My Orders
            <div class="page-title-subheading">All my orders
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Table Code Start here-->
    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5">
          <md-ripple>
            <md-card-content>
              <div class="mt-5">
                <div v-if="PurchasedLinks.length < 1">
                  <noDataComponent/>
                </div>
              </div>
              <div v-if="PurchasedLinks.length >= 1">
                <md-table>
                  <md-table-row>
                    <md-table-head class="text-center" md-numeric>ID</md-table-head>
                    <md-table-head class="text-center">Order Id</md-table-head>
                    <md-table-head class="text-center">Address</md-table-head>
                    <md-table-head class="text-center" v-if="$STORE.state.user.type == 'admin'">Payment ID
                    </md-table-head>
                    <md-table-head class="text-center">Payment Status</md-table-head>
                    <md-table-head class="text-center">Products</md-table-head>
                    <md-table-head class="text-center">Total</md-table-head>
                    <md-table-head class="text-center">Status</md-table-head>
                    <md-table-head class="text-center">Date</md-table-head>
                    <md-table-head class="text-center" v-if="$STORE.state.user.type == 'admin'">Actions</md-table-head>
                    <md-table-head class="text-center">Tracking Number</md-table-head>
                  </md-table-row>


                  <md-table-row v-for="(data, row) in PurchasedLinks" :key="data.id">
                    <md-table-cell class="text-center" md-numeric>{{ row + 1 }}</md-table-cell>
                    <md-table-cell class="text-center">{{ data.id }}</md-table-cell>
                    <md-table-cell class="text-center">{{ data.address }}</md-table-cell>
                    <md-table-cell class="text-center" v-if="$STORE.state.user.type == 'admin'">{{
                        data.payment
                      }}
                    </md-table-cell>
                    <md-table-cell class="text-center flex_table">
                      <div>
                        <md-chip :class="getStatusClass(data.payment_status)">{{ data.payment_status }}</md-chip>
                      </div>

                      <div v-if="data.payment_channel.includes('crypto') ">
                        <md-button :disabled="data.payment_id === 0" @click="refreshPaymentStatus(data.id)" class="md-fab md-mini refresh_btn">
                          <md-icon :class="{'spin': spinning}">autorenew</md-icon>
                        </md-button>

                      </div>
                    </md-table-cell>
                    <md-table-cell class="text-center">
                      <div>
                        <md-button @click="openProductModal(data.id)"
                                   class="md-icon-button md-dense md-raised md-primary">
                          <md-icon>inventory_2</md-icon>
                        </md-button>
                      </div>
                    </md-table-cell>
                    <md-table-cell class="text-center flex_table"> ${{ data.total }}</md-table-cell>
                    <md-table-cell class="text-center flex_table">
                      <md-chip :class="getStatusClass(data.status)">Order {{ data.status }}</md-chip>
                    </md-table-cell>
                    <md-table-cell class="text-center">{{ data.created_at | datetime }}</md-table-cell>

                    <md-table-cell v-if="$STORE.state.user.type == 'admin'" class="text-center">

                      <div
                          class="btn-group"
                          role="group"
                          aria-label="Basic example"
                      >

                        <md-button
                            v-if="data.status == 'pending' && data.payment_status == 'finished' "
                            class="md-fab md-mini bg-success"
                            @click="updateStatus(data.id, 'received')"
                        >
                          <md-icon>check_circle</md-icon>
                          <md-tooltip md-direction="bottom"
                          >Order Received
                          </md-tooltip
                          >
                        </md-button>

                        <md-button
                            v-if="data.status == 'received'"
                            class="md-fab md-mini bg-success"
                            @click="updateStatus(data.id, 'packed')"
                        >
                          <md-icon>check_circle</md-icon>
                          <md-tooltip md-direction="bottom"
                          >Order Packed
                          </md-tooltip
                          >
                        </md-button>

                        <md-button
                            v-if="data.status == 'packed'"
                            class="md-fab md-mini bg-success"
                            @click="updateStatus(data.id, 'shipped')"
                        >
                          <md-icon>check_circle</md-icon>
                          <md-tooltip md-direction="bottom"
                          >Order Shipped
                          </md-tooltip
                          >
                        </md-button>

                        <md-button
                            v-if="data.status == 'shipped'"
                            class="md-fab md-mini bg-success"
                            @click="updateStatus(data.id, 'delivered')"
                        >
                          <md-icon>check_circle</md-icon>
                          <md-tooltip md-direction="bottom"
                          >Order Delivered
                          </md-tooltip
                          >
                        </md-button>

                        <md-button
                            v-if="data.status == 'pending' || data.status == 'received' || data.status == 'packed' "
                            class="md-fab md-mini bg-danger"
                            @click="updateStatus(data.id, 'canceled')"

                        >
                          <md-icon>close</md-icon>
                          <md-tooltip md-direction="bottom"
                          >Cancel Order
                          </md-tooltip
                          >
                        </md-button>

                      </div>

                    </md-table-cell>

                    <md-table-cell class="text-center">

                      <div v-if="data.status == 'shipped' && $STORE.state.user.type == 'admin'">
                        <md-field>
                          <!--                          <md-icon>local_shipping</md-icon>-->
                          <label>Input Tracking</label>
                          <md-input placeholder="e.g UB77543169HK, UB77543169HK"
                                    v-model="form.tracking_number"></md-input>
                        </md-field>

                        <md-button @click="uploadTracking(data.id)" class="md-raised md-dense bg-success">
                          Upload Tracking
                        </md-button>

                      </div>

                      <div
                          v-if="data.status == 'shipped' || data.status == 'delivered'">
                        <md-button @click="()=>{openTrackingModal(data.tracking)}"
                                   class="md-icon-button md-dense md-raised md-primary">
                          <md-icon>info</md-icon>
                          <md-tooltip md-direction="bottom">{{ data.tracking }}</md-tooltip>
                        </md-button>
                      </div>

                    </md-table-cell>


                  </md-table-row>

                </md-table>
              </div>
            </md-card-content>

            <div class="col-md-12 mb-3 mt-3">
              <div class="justify-content-center" style="display: flex !important;">
                <div>
                  <pagination
                      :data="pagination"
                      @pagination-change-page="loadAllOrder"
                      :limit="5"
                      page="1"
                  ></pagination>
                </div>

              </div>
              </div>
          </md-ripple>
        </md-card>

      </div>
    </div>

    <modal
        name="tracking_modal"
        :adaptive="true"
        :scrollable="true"
        height="auto"
    >
      <div class="mt-3 text-center">
        <h4>
          Tracking Number(s)
        </h4>
      </div>

      <div class="container">
        <div class="mt-5 mb-5">
          <div v-if="modal_tracking != '' ">
            <ul v-for="(tracking, index) in modal_tracking.split(',')" :key="index">
              <li>{{ tracking }}</li>
            </ul>
          </div>
        </div>
      </div>
    </modal>

    <modal
        name="product_modal"
        :adaptive="true"
        :scrollable="true"
        height="auto"
        classes="test"
    >
      <div class="mt-3 text-center">
        <h4>
          Products
        </h4>
      </div>

      <div class="container">
        <div class="mt-5 mb-5">
          <div>
            <div class="box">
              <div class="flex-item-left" v-for="(product, index) in orderbyProduct.products  " :key="index">
                <div class="cont">
                  <div class="prod_pic">
                    <img :src="getImagePath(product.image)" alt="">
                  </div>
                  <div class="prod_det">
                    <div class="top_lol">
                      <div class="prod_name mt-2">
                        {{ product.name }}
                      </div>
                    </div>
                    <div class="choice">
                      <div class="one">
                        {{ product.pivot.option_tag }}:{{ product.pivot.option }}/{{
                          product.pivot.option_tag1
                        }}:{{ product.pivot.option_1 }}
                      </div>
                    </div>
                    <div class="price_cont">
                      <div class="price">${{ product.price }}</div>
                      <div class="amount">
                        Qty: {{ product.pivot.quantity }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>
            <!--       --------------------------------- ------------ -->
            <div class="mt-3 text-center">
              <h4>
                <b>
                  Delivery Address
                </b>
              </h4>
            </div>

            <div class="container">
              <div class="row justify-content-center">
                <div class="mr-2"><b>Full Name: </b> {{ orderbyProduct?.address?.full_name }}</div>
                <div class="mr-2"><b>Mobile Number: </b>  {{ orderbyProduct?.address?.mobile }}</div>
                <div class="mr-2"><b>Address: </b>  {{ orderbyProduct?.address?.street_add }}</div>
                <div class="mr-2"><b>City:</b>  {{ orderbyProduct?.address?.city }}</div>
                <div class="mr-2"><b>State:</b>  {{ orderbyProduct?.address?.state }}</div>
                <div class="mr-2"><b>Zip: </b> {{ orderbyProduct?.address?.zip }}</div>
                <div class="mr-2"><b>Country: </b> {{ orderbyProduct?.address?.country }}</div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<style>

.trans_pending {
  background: #cccccc !important;
}

.trans_waiting {
  background: #FFFF99 !important;
}

.trans_confirming {
  background: #ADD8E6 !important;
}

.trans_confirmed {
  background: #32CD32 !important;
}

.trans_failed {
  background: #FF0000 !important;
}

.trans_refunded {
  background: #800080 !important;
  color: white !important;
}

.trans_expired {
  background: #FFCCCC !important;
}

.trans_finished {
  background: #006400 !important;
  color: white;
}

.trans_partial {
  background: #FFA500 !important;
}

.trans_sending {
  background: #90EE90 !important;
}

.order_received {
  background: #ADD8E6 !important;
}

.order_packed {
  background: #FFFF99 !important;
}

.order_shipped {
  background: #32CD32 !important;
}

.order_delivered {
  background: #006400 !important;
  color: white !important;
}

.order_canceled {
  background: #FF0000 !important;
}

.remove-bg {
  background: unset !important;
}

.flex_table > div {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: space-evenly !important;
  align-items: center !important;
}

.refresh_btn {
  background: #ADD8E6 !important;
}

.spin {
  animation: spin 10s linear;

  @keyframes spin {
    from {
      transform: rotate(0deg)
    }
    to {
      transform: rotate(360deg);
    }
  }
}


.box {
  //border: 1px solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around; /* Space boxes evenly */
}


.flex-item-left {
  width: calc(33% - 20px); /* 25% width for each box minus spacing */
  height: 100px; /* Set the height of each box */
  background-color: #666363;
  margin: 10px; /* Add spacing around each box */
  box-sizing: border-box;
  border-radius: 10px;
}

@media screen and (max-width: 600px) {
  .flex-item-left, label.radio-card {
    width: calc(100% - 20px) !important; /* Full width for each box on small screens */
  }
}

@media screen and (min-width: 600px) and (max-width: 1100px) {
  .flex-item-left, label.radio-card {
    width: calc(50% - 20px) !important; /* Display 2 boxes per row */
  }
}

@media screen and (min-width: 1101px) and (max-width: 1399px) {
  .flex-item-left, label.radio-card {
    width: calc(33.3% - 20px) !important; /* Display 2 boxes per row */
  }
}

.cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.prod_pic {
  width: 100px;
  //border: 1px solid green;
  height: 100px;
}

.prod_det {
  width: calc(100% - 100px);
  //border: 1px solid red;
  height: 100px;
}

.prod_name {
  width: 100%;
  //border: 1px solid purple;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: white;
  padding-right: 5px;
}

.choice {
  margin-top: 10px;
  width: 100%;
  border-radius: 50px;
  background: #202020;
  color: #B6B6B6;
  //cursor: pointer;
  display: flex;
  justify-content: space-between;
  height: 25px;
}

.one {
  padding-left: 10px;
  font-size: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}

.price_cont {
  display: flex;
  margin-top: 10px;
  justify-content: space-between;
  color: white;
}

.price {
  font-size: 16px;
  line-height: 25px;
  font-weight: bolder;

}

.amount {
  //border: 1px solid black;
  margin-right: 10px;
  font-size: 16px;
}

.test {
  width: 70% !important; /* Default width */
  //padding: 20px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  left: 0 !important;
  height: auto;
  top: 366px;
  right: 0 !important;
  margin: 0 auto !important;
  border-radius: 20px !important;
}

/* Media query for small screens */
@media (max-width: 768px) {
  .test {
    width: 100%; /* Full width on small screens */
    max-width: none; /* Remove max-width constraint */
    margin: 0; /* Adjust margin for full width */
  }
  .app-main__inner > .vm--container {
    left: unset !important;
    margin: 0 auto;
  }
}


.app-main__inner > .vm--container {
  left: 100px;
  margin: 0 auto;
}

</style>