<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">dialpad</md-icon>
          </div>
          <div class="text-black">Port Phone Number
            <div class="page-title-subheading">
              Port a Valid Phone Number
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>
            <md-card-header>
              <div class="md-title text-capitalize">Port Lead Phone Numbers</div>
            </md-card-header>

            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="row">

                    <div class="col-md-3">
                      <md-field>
                        <label>Phone Number</label>
                        <md-input v-model="form.number" placeholder="+12039991528"></md-input>
                        <span class="md-helper-text">Input a valid Phone Number</span>
                      </md-field>
                    </div>

                    <div class="col-md-3">
                      <md-field>
                        <label>Amount</label>
                        <md-input v-model="form.amount" placeholder="$1000"></md-input>
                        <span class="md-helper-text">Input a valid amount with $ sign</span>
                      </md-field>
                    </div>

                    <div class="col-md-3">
                      <md-field>
                        <label>Bank Name</label>
                        <md-input v-model="form.message_body" placeholder="MnT"></md-input>
                        <span class="md-helper-text">Input a valid Bank Name</span>
                      </md-field>
                    </div>

                    <div class="col-md-3">
                      <md-field>
                        <label>Target Name</label>
                        <md-input v-model="form.name" placeholder="Kimberly"></md-input>
                        <span class="md-helper-text">Input a Name</span>
                      </md-field>
                    </div>


                  </div>

                </div>
                <div class="mt-5">
                  <div class="text-center">
                    <md-button @click="submit()" class="md-raised text-white"
                               style="background-color: green !important;">
                      port number
                    </md-button>
                  </div>
                </div>
              </div>

            </md-card-content>
          </md-ripple>
        </md-card>
      </div>

      <div class="col-md-12" v-if="hide">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>

            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="mt-5">
                    <md-table>
                      <md-table-row>
                        <md-table-head class="text-center" md-numeric>ID</md-table-head>
                        <md-table-head class="text-center">Direction</md-table-head>
                        <md-table-head class="text-center">Source</md-table-head>
                        <md-table-head class="text-center">Destination</md-table-head>
                        <md-table-head class="text-center">Called at</md-table-head>
                        <md-table-head class="text-center">Answered at</md-table-head>
                        <md-table-head class="text-center">Ended at</md-table-head>
                        <md-table-head class="text-center">Status</md-table-head>
                        <md-table-head class="text-center">Action</md-table-head>
                      </md-table-row>
                      <md-table-row v-for="row in results" :key="row.id">
                        <md-table-cell class="text-center" md-numeric>{{ row.id }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.direction }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.source }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.destination }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.createdAt }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.answeredAt }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.endedAt }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.status }}</md-table-cell>
                        <md-table-cell class="text-center">
                          <div
                              class="btn-group"
                              role="group"
                              aria-label="Basic example"
                          >
                            <md-button
                                class="md-fab md-mini bg-danger"
                                @click="getRecording()"
                                v-if="row.status == 'hangup' "
                            >
                              <md-icon>mic</md-icon>
                              <md-tooltip md-direction="bottom"
                              >Get call Recording
                              </md-tooltip
                              >
                            </md-button>

                          </div>
                        </md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>

                </div>

              </div>

            </md-card-content>
          </md-ripple>
        </md-card>
      </div>


    </div>


  </div>
</template>

<script>
import {getCallInfo, getCallRecording, portNumber} from "@/services/numbers/numberService";

export default {
  name: "PortComponent",
  data() {
    return {
      // eslint-disable-next-line no-undef
      form: {
        number: "",
        message_body: "",
        name: "",
      },
      id: null,
      hide: false,
      results: [],
      filtered_results: [],
      filtered: false,
      submitting: false,
    }
  },

  methods: {
    submit() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        portNumber(this.form).then((response) => {
          loader.hide();
          this.id = response.data.data.id
          this.$notify({
            message: response.data.message,
            type: "success",
            top: true,
            bottom: false,
            left: false,
            right: true,
            showClose: false,
            closeDelay: 2500,
            offset: 50,
          })
          this.hide = true;
          // eslint-disable-next-line no-unused-vars
          const refresh = setInterval(() => {
            this.getCallInformation(this.id, refresh);
          }, 2000);

        }).catch((error) => {
          loader.hide();
          this.$Progress.fail();
          if (error.response.status == 422) {
            for (let i in error.response.data.error) {
              setTimeout(() => {
                this.$notify({
                  message: error.response.data.error[i][0],
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }, 500);
            }
          }

          if (error.response.status == 401) {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            })
          }

          if (error.response.status == 500) {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            })
          }
          this.submitting = false
        })
      }, 1000)
    }
    ,

    // eslint-disable-next-line no-unused-vars
    getCallInformation(call_id, refresh) {
      getCallInfo(call_id, refresh).then((response) => {
        this.results = response.data.data.items

        const stats = response.data.data.items[0].status;

        if (stats === 'hangup') {
          clearInterval(refresh);
        }
      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      })
    }
    ,

    getRecording() {
      getCallRecording(this.id).then((response)=>{
        console.log(response);
      }).catch((error)=>{
        this.$notify({
          message: error.response.data.message,
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      })
    }
    ,

    convert(inputObj) {
      const newArr = Object.values(inputObj);
      newArr.forEach(obj => {
        if (obj.descendants) obj.descendants = this.filter_by_type_mobile(obj.descendants);
        if (obj.children) obj.children = this.filter_by_type_mobile(obj.children);
      });
      return this.results = newArr;

    }
    ,

    filter(data) {
      this.filtered = true;
      // alert(data);
      this.filtered_results = this.results.filter(object => {
        return object.carrier.type == data;
      });
    }
    ,

    reset_filter() {
      this.filtered = false;
      this.filtered_results = [];

    }
  }
  ,

  created() {

  }
}
</script>

<style scoped>

</style>