<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">person_add</md-icon>
          </div>
          <div class="text-black">Add User
            <div class="page-title-subheading">
              Add a new user to the system
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>
            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="mt-5">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <md-field>
                            <label>Email</label>
                            <md-input type="email" v-model="form.email"></md-input>
                          </md-field>
<!--                          <div-->
<!--                              v-if="form.errors.has('email')"-->
<!--                              class="md-error"-->
<!--                              style="color: red"-->
<!--                              v-html="form.errors.get('email')"-->
<!--                          />-->
                        </div>

                        <div class="col-md-6">
                          <md-field>
                            <label>Username</label>
                            <md-input v-model="form.username"></md-input>
                          </md-field>
<!--                          <div-->
<!--                              v-if="form.errors.has('username')"-->
<!--                              class="md-error"-->
<!--                              style="color: red"-->
<!--                              v-html="form.errors.get('username')"-->
<!--                          />-->
                        </div>

                        <div class="col-md-12">
                          <div class="text-center">
                            <md-radio v-model="form.type" value="admin">Admin</md-radio>
                            <md-radio v-model="form.type" value="user">User</md-radio>
                          </div>
<!--                          <div-->
<!--                              v-if="form.errors.has('type')"-->
<!--                              class="md-error"-->
<!--                              style="color: red"-->
<!--                              v-html="form.errors.get('type')"-->
<!--                          />-->
                        </div>

                        <div class="col-md-4">
                          <md-field>
                            <label>Password</label>
                            <md-input type="password" readonly v-model="form.password"></md-input>
                          </md-field>
<!--                          <div-->
<!--                              v-if="form.errors.has('password')"-->
<!--                              class="md-error"-->
<!--                              style="color: red"-->
<!--                              v-html="form.errors.get('password')"-->
<!--                          />-->
                        </div>

                        <div class="col-md-4">
                          <div class="mt-3 text-center">
                            <md-button class="md-dense md-raised md-primary" @click="generate(8)">Generate Password
                            </md-button>
                          </div>

                        </div>

                        <div class="col-md-4">
                          <md-field >
                            <label>Password Confirmation</label>
                            <md-input type="password" readonly v-model="form.password_confirmation"></md-input>
                          </md-field>
<!--                          <div-->
<!--                              v-if="form.errors.has('password_confirmation')"-->
<!--                              class="md-error"-->
<!--                              style="color: red"-->
<!--                              v-html="form.errors.get('password_confirmation')"-->
<!--                          />-->
                        </div>

                        <div class="col-md-12">
                          <div class="mt-3 text-center">
                            <md-button class="md-dense md-raised md-primary" @click="submit()">Create User</md-button>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                </div>

              </div>

            </md-card-content>

          </md-ripple>
        </md-card>
      </div>


    </div>


  </div>
</template>

<script>
import {createUser} from "@/services/user/userService";

export default {
  name: "CreateUserComponent",
  data() {
    return {
      // eslint-disable-next-line no-undef
      form: {
        email: "",
        username: "",
        type: "",
        password: "",
        password_confirmation: "",
      },
      index: 1,
      results: [],
      filtered_results: [],
      filtered: false,
      submitting: false,
    }
  },

  methods: {
    // getValidationClass(fieldName) {
    //   const field = this.form.errors.has(fieldName);
    //   if (field) {
    //     return "md-invalid";
    //   }
    // },

    generate(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }
      this.form.password = result;
      this.form.password_confirmation = result;
    },

    submit() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        createUser(this.form)
            .then((response) => {
              loader.hide();
              Object.keys(this.form).forEach((i) => this.form[i] = "");
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })
            })
            .catch((error) => {
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.errors) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.errors[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 1000)
    },

  }
}
</script>

<style scoped>

</style>