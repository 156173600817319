/* eslint-disable */
// noinspection DuplicatedCode

import {Form} from 'vform'
import axios from "axios";
import {GET_HEADERS, POST_HEADERS} from "@/config/axios_config";

let BASE_API_URL;
const form = new Form();


BASE_API_URL = process.env.VUE_APP_API_BASE_URL;

export async function sendMail(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/mail/send', POST_HEADERS)
}

// export async function logoutService() {
//     return await axios.get(BASE_API_URL + '/api_logout', GET_HEADERS);
// }