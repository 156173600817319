/* eslint-disable */
// noinspection DuplicatedCode

import {Form} from 'vform'
import axios from "axios";
import {GET_HEADERS, POST_HEADERS} from "@/config/axios_config";

let BASE_API_URL;
const form = new Form();


BASE_API_URL = process.env.VUE_APP_API_BASE_URL;


export async function userMessageBroadcast(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/user/broadcast', POST_HEADERS)
}

export async function saveCountry(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/setting/country', POST_HEADERS)
}

export async function getCountry(page) {
    return await axios.get(BASE_API_URL + '/setting/country' + "?page=" + page, GET_HEADERS);
}

export async function loadCountry(page) {
    return await axios.get(BASE_API_URL + '/setting/country/get', GET_HEADERS);
}

export async function deleteCountry(id) {
    return await axios.delete(BASE_API_URL + `/setting/country/` + id, GET_HEADERS);
}

export async function saveCarrier(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/setting/carrier', POST_HEADERS)
}

export async function getCarriers(page) {
    return await axios.get(BASE_API_URL + '/setting/carrier' + "?page=" + page, GET_HEADERS);
}

export async function deleteCarrier(id) {
    return await axios.delete(BASE_API_URL + `/setting/carrier/` + id, GET_HEADERS);
}

export async function getCarrierByCountry(data) {
    return await axios.get(BASE_API_URL + '/setting/carrier/byCountry/' + data, GET_HEADERS);
}
export async function saveCategory(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/setting/category', POST_HEADERS)
}

export async function getCategories(page) {
    return await axios.get(BASE_API_URL + '/setting/category' + "?page=" + page, GET_HEADERS);
}

export async function deleteCategory(id) {
    return await axios.delete(BASE_API_URL + `/setting/category/` + id, GET_HEADERS);
}

export async function loadCategories(page) {
    return await axios.get(BASE_API_URL + '/setting/category/get', GET_HEADERS);
}

export async function saveShop(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/setting/shop', POST_HEADERS)
}

export async function getShops(page) {
    return await axios.get(BASE_API_URL + '/setting/shop' + "?page=" + page, GET_HEADERS);
}

export async function deleteShop(id) {
    return await axios.delete(BASE_API_URL + `/setting/shop/` + id, GET_HEADERS);
}

export async function loadShops(page) {
    return await axios.get(BASE_API_URL + '/setting/shop/get', GET_HEADERS);
}

