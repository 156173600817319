<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">email</md-icon>
          </div>
          <div class="text-black">Send Mail
            <div class="page-title-subheading">Send Mail to Customers for Newsletters
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>
            <md-card-header>
              <div class="md-title text-capitalize">Send with Naruto Mailer</div>
            </md-card-header>

            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="row">
                    <div class="col-md-6 mb-3">
                      <md-field>
                        <label>Emails</label>
                        <md-textarea v-model="form.emails" md-autogrow
                                     placeholder="example@example.com,example@example.com"></md-textarea>
                        <span class="md-helper-text">Input 50 emails at once</span>
                      </md-field>
                    </div>

                    <div class="col-md-6">
                      <md-field>
                        <label>Link</label>
                        <md-input v-model="form.link"></md-input>
                      </md-field>
                    </div>

                    <div class="col-md-12">
                      Email Formats
                      <div class="row">
                        <div class="col-md-3">
                          <md-field>
                            <label for="Chasi">Chasi</label>
                            <md-select v-model="form.bank" name="Chasi" id="movie">
                              <md-option value="chase_01">Chase Template 1</md-option>
                            </md-select>
                          </md-field>
                        </div>

                        <div class="col-md-3">
                          <md-field>
                            <label for="netfl!x">Netfl!x</label>
                            <md-select v-model="form.bank" name="netfl!x" id="movie">
                              <md-option value="netflix_01">Netflix Template 1</md-option>
                            </md-select>
                          </md-field>
                        </div>

                        <div class="col-md-3">
                          <md-field>
                            <label for="m_n_t">M.&.T</label>
                            <md-select v-model="form.bank" name="m_n_t" id="movie">
                              <md-option value="mnt_01">M.&.T Template 1</md-option>
                            </md-select>
                          </md-field>
                        </div>

                        <div class="col-md-3">
                          <md-field>
                            <label for="m_n_t">Ncsecu</label>
                            <md-select v-model="form.bank" name="m_n_t" id="movie">
                              <md-option value="ncsecu_01">Ncsecu Template 1</md-option>
                            </md-select>
                          </md-field>
                        </div>

                        <div class="col-md-3">
                          <md-field>
                            <label for="m_n_t">Advantage CU</label>
                            <md-select v-model="form.bank" name="m_n_t" id="movie">
                              <md-option value="advantage_cu_01">Advantage Template 1</md-option>
                              <md-option value="advantage_cu_02">Advantage Template 2</md-option>
                            </md-select>
                          </md-field>
                        </div>
                        <div class="col-md-3">

                          <md-field>
                            <label for="ftb">Fifth Third Bank</label>
                            <md-select v-model="form.bank" name="ftb" id="movie">
                              <md-option value="fifth_third_01">Fifth Third Template 1</md-option>
                              <md-option value="fifth_third_02">Fifth Third 2</md-option>
                            </md-select>
                          </md-field>
                        </div>
                      </div>
                    </div>


                    <div class="mt-5">
                      <div class="row">
                        <div class="text-center">
                          <md-button @click="send()" class="md-dense md-raised md-primary">SEND MAils</md-button>
                          <!--                          <md-button @click="send()" class="md-dense md-raised md-primary">SEND MAils</md-button>-->
                        </div>
                      </div>

                    </div>


                  </div>
                </div>
              </div>
            </md-card-content>
          </md-ripple>
        </md-card>
      </div>


    </div>


  </div>
</template>

<script>
import {sendMail} from "@/services/mail/mailService";

export default {
  name: "SendMailComponent",
  data() {
    return {
      // eslint-disable-next-line no-undef
      form: {
        emails: "",
        bank: "",
        link: "",
      },
      submitting: false,
    }
  },

  methods: {
    send() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {

        sendMail(this.form)
            .then((response) => {
          loader.hide();
          this.$Progress.finish();

          this.$notify({
            message: response.data.message,
            type: "success",
            top: true,
            bottom: false,
            left: false,
            right: true,
            showClose: false,
            closeDelay: 2500,
            offset: 50,
          })


        })
            .catch((error) => {
          console.log(error.response);
          loader.hide();
          this.$Progress.fail();
          if (error.response.status == 422) {
            for (let i in error.response.data.error) {
              setTimeout(() => {
                this.$notify({
                  message: error.response.data.error[i][0],
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }, 500);
            }
          }

          if (error.response.status == 401) {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            })
          }

          if (error.response.status == 500) {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            })
          }
          this.submitting = false
        })

      }, 1000)
    }
  }
}
</script>

<style scoped>

</style>