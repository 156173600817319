import store from "@/store";

export const GET_HEADERS = {
    headers: {
        "Authorization": 'Bearer ' + store.getters.isAuthenticated,
    }
};

export const POST_HEADERS = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.getters.isAuthenticated
    }
};

export const SMTP_GET_HEADERS = {
    headers: {
        "Authorization": 'Bearer ' + store.getters.isAuthenticatedSMTP,
    }
};

export const SMTP_POST_HEADERS = {
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + store.getters.isAuthenticatedSMTP
    }
};