import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
    plugins: [createPersistedState({
        storage: window.sessionStorage,
    })],

    state: {
        user: false,
        cartCount: 0,
        subscription: null,
        notification: {
            notifications: '',
            count: 0,
        },
        isAuthenticated: sessionStorage.getItem("access-token") !== null,
        isAuthenticatedSMTP: sessionStorage.getItem("smtp-access-token") !== null,
    },


    getters: {
        isAuthenticated(state) {
            return state.isAuthenticated;
        },
        isAuthenticatedSMTP(state) {
            return state.isAuthenticatedSMTP;
        },
        subscription(state) {
            return state.subscription;
        },
        notification(state) {
            return state.notification;
        },
        user(state) {
            return state.user;
        },
        cartCount(state) {
            return state.cartCount;
        },
    },


    mutations: {
        updateUser(state, data) {
            state.user = data.data;
            if (state.user != false) {
                state.isAuthenticated = sessionStorage.getItem("access-token")
                state.isAuthenticatedSMTP = sessionStorage.getItem("smtp-access-token")
            } else {
                state.isAuthenticated = null;
                sessionStorage.clear();
            }
        },

        updateSubscription(state, data) {
            state.subscription = data;
        },

        updateCartCount(state, data) {
            state.cartCount = data.data;
        },

        updateNotification(state, data){
            state.notification.notifications = data.notifications.original.data;
            state.notification.count = data.count;
        }

    },

    actions: {
        updateUser: ({commit}, data) => {
          commit('updateUser',data)
        },
        updateCartCount: ({commit}, data) => {
          commit('updateCartCount',data)
        },
        updateNotification:({commit},data) => {
            commit('updateNotification', data)
        }
    }
});
