<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">payments</md-icon>
          </div>
          <div class="text-black">SMTP to SMS Subscription
            <div class="page-title-subheading">
              view all SMTP to SMS Subscription
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>
            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="mt-5">
                    <div v-if="credit.length < 1">
                      <noDataComponent />
                    </div>
                    <md-table v-if="credit.length >= 1">
                      <md-table-row>
                        <md-table-head class="text-center" md-numeric>ID</md-table-head>
                        <md-table-head class="text-center">User ID</md-table-head>
                        <md-table-head class="text-center">Status</md-table-head>
                        <md-table-head class="text-center">Is Current</md-table-head>
                        <md-table-head v-if="$STORE.state.user.type == 'admin'" class="text-center">Action
                        </md-table-head>
                      </md-table-row>
                      <md-table-row v-for="row in credit" :key="row.id">
                        <md-table-cell class="text-center" md-numeric> {{ row.id }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.user.username }}</md-table-cell>
                        <md-table-cell class="text-center">
                          <md-chip :class="getStatusClass(row.status)">{{ row.status }}</md-chip>
                        </md-table-cell>
                        <md-table-cell class="text-center">{{ row.current }}</md-table-cell>
                        <md-table-cell v-if="$STORE.state.user.type == 'admin'" class="text-center">
                          <div
                              class="btn-group"
                              role="group"
                              aria-label="Basic example"
                          >

                            <md-button
                                v-if="row.status == 'pending' || row.status == 'failed' "
                                class="md-fab md-mini bg-success"
                                @click="approve(row.id)"

                            >
                              <md-icon>check_circle</md-icon>
                              <md-tooltip md-direction="bottom"
                              >Approve Transaction
                              </md-tooltip
                              >
                            </md-button>

                            <md-button
                                v-if="row.status == 'pending' || row.status == 'approved' "
                                class="md-fab md-mini bg-danger"
                                @click="decline(row.id)"

                            >
                              <md-icon>close</md-icon>
                              <md-tooltip md-direction="bottom"
                              >Decline Transaction
                              </md-tooltip
                              >
                            </md-button>

                          </div>
                        </md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>

                </div>

              </div>

            </md-card-content>

            <div class="col-md-12 mb-3 mt-3">
              <div class="justify-content-center" style="display: flex !important;">
                <div>
                  <pagination
                      :data="pagination"
                      @pagination-change-page="loadTransactions"
                      :limit="5"
                      page="1"
                  ></pagination>
                </div>

              </div>
            </div>

          </md-ripple>
        </md-card>
      </div>


    </div>


  </div>
</template>

<script>
import {
  approveSmtpTransactions,
  declineSmtpTransactions,
  loadSmtpTransactions
} from "@/services/transactions/transactionService";
import noDataComponent from "../../utils/noDataComponent.vue";

export default {
  name: "SmtpToSmsTransactions",
  components: {noDataComponent},
  data() {
    return {
      credit: [],
      filter: "All",
      pagination: {},
      submitting: false,
    };
  },
  methods: {
    getStatusClass(status) {
      if (status == 'approved') {
        return "bg-success";
      } else if (status == "pending") {
        return "md-primary";
      } else if (status == "failed") {
        return "md-accent";
      }
    },

    prepPagination(data) {
      this.pagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    loadTransactions(page = 1) {
      loadSmtpTransactions(page).then((response) => {
        this.prepPagination(response.data);
        this.credit = response.data.data;
        console.log(this.credit.length)
      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      })
    },

    approve(id) {
      approveSmtpTransactions(id).then(() => {
        this.loadTransactions();
      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      })
    },
    decline(id) {
      declineSmtpTransactions(id)
          .then(() => {
            this.loadTransactions();
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },
  },

  created() {

  },

  mounted() {
    this.loadTransactions();
  }
}
</script>

<style scoped>

</style>