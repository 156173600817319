<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">textsms</md-icon>
          </div>
          <div class="text-black">Fund your wallet
            <div class="page-title-subheading">

            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
        name="subscriptions"
        :adaptive="true"
        :scrollable="true"
        height="auto"
    >
      <div class="mt-3 text-center">
        <h4>
          Transfer ${{ form.amount }} to the BTC address below
        </h4>
      </div>

      <div class="container">
        <div class="text-center mt-5">
          <div>
            <img style="height: 400px"
                 src="../../../../public/assets/images/qr-bc1qmlwhjpv8jzsfd744kav3a3vkwaqfkfxvca82vx.png" alt="">

            <div class="mt-3 mb-3">
              BTC Address: bc1qmlwhjpv8jzsfd744kav3a3vkwaqfkfxvca82vx
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-12" style="text-align: initial; justify-content: space-between; display: flex;">
                <md-button class="md-dense md-raised md-accent" @click="close">Cancel</md-button>
                <md-button class="md-dense md-raised md-primary" @click="confim_tran">Confirm ${{
                  form.amount
                  }}
                </md-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </modal>

    <modal
        name="usdt_erc"
        :adaptive="true"
        :scrollable="true"
        height="auto"
    >
      <div class="mt-3 text-center">
        <h4>
          Transfer ${{ form.amount }} to the USDT(ERC 20) address below
        </h4>
      </div>

      <div class="container">
        <div class="text-center mt-5">
          <div>
            <img style="height: 400px"
                 src="../../../../public/assets/images/qr-0x7f400C9c84c064bb1380D41a0f5E03DcC6aF529a.png" alt="">

            <div class="mt-3 mb-3">
              USDT(ERC 20) Address: 0x7f400C9c84c064bb1380D41a0f5E03DcC6aF529a
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-12" style="text-align: initial; justify-content: space-between; display: flex;">
                <md-button class="md-dense md-raised md-accent" @click="close">Cancel</md-button>
                <md-button class="md-dense md-raised md-primary" @click="confim_tran">Confirm ${{
                  form.amount
                  }}
                </md-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </modal>

    <modal
        name="usdt_trc"
        :adaptive="true"
        :scrollable="true"
        height="auto"
    >
      <div class="mt-3 text-center">
        <h4>
          Transfer ${{ form.amount }} to the USDT(TRC 20) address below
        </h4>
      </div>

      <div class="container">
        <div class="text-center mt-5">
          <div>
            <img style="height: 400px"
                 src="../../../../public/assets/images/qr-TKfp8ndw8218r3HY6K5ywHLEJ1eXFrhQ6S.png" alt="">

            <div class="mt-3 mb-3">
              USDT(TRC 20) Address: TKfp8ndw8218r3HY6K5ywHLEJ1eXFrhQ6S
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-12" style="text-align: initial; justify-content: space-between; display: flex;">
                <md-button class="md-dense md-raised md-accent" @click="close">Cancel</md-button>
                <md-button class="md-dense md-raised md-primary" @click="confim_tran">Confirm ${{
                  form.amount
                  }}
                </md-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </modal>

    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>
            <md-card-header>
              <div class="md-title text-capitalize">Fund your wallet</div>
            </md-card-header>

            <div class="text-center">
              Since the gas fee of BTC is very high now, if your deposit is less than 1,000 US
              dollars, it is recommended that you choose other currencies.
            </div>
            <div class="text-center">
              由于现在BTC的汽油费非常高，如果您充值少于1000美金，建议您选择其他币种。
            </div>

            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="row">

                    <div class="col-md-6">
                      <md-field>
                        <label for="movie">Amount</label>
                        <md-select v-model="form.amount" name="movie" id="movie">
                          <md-option value="40">$40</md-option>
                          <md-option value="60">$60</md-option>
                          <md-option value="100">$100</md-option>
                          <md-option value="120">$120</md-option>
                          <md-option value="150">$150</md-option>
                          <md-option value="200">$200</md-option>
                        </md-select>
                      </md-field>
                    </div>

                    <div class="col-md-6">
                      <md-field>
                        <label for="movie">Crypto Currency</label>
                        <md-select v-model="form.crypto" name="movie" id="movie">
                          <md-option value="btc">BTC</md-option>
                          <md-option value="usdt_erc">USDT (ERC20)</md-option>
                          <md-option value="usdt_trc">USDT (TRC20)</md-option>
                        </md-select>
                      </md-field>
                    </div>


                  </div>

                </div>


                <div class="mt-5">
                  <div class="text-center">
                    <!--                      <md-button disabled="" @click="submit()" class="md-raised text-white"-->
                    <!--                                 style="background-color: green !important;">-->
                    <!--                        Send SMS with twilio-->
                    <!--                      </md-button>-->
                    <!--                      <md-button @click="submitVonage()" class="md-raised text-white"-->
                    <!--                                 style="background-color: green !important;">-->
                    <!--                        Send SMS with Vonage-->
                    <!--                      </md-button>-->
                    <md-button @click="showDialog" class="md-raised text-white"
                               style="background-color: green !important;">
                      Fund Wallet
                    </md-button>

                  </div>

                </div>

              </div>
            </md-card-content>
          </md-ripple>
        </md-card>
      </div>


    </div>


  </div>
</template>

<script>

import {fundWallet} from "@/services/wallet/walletService";

export default {
  name: "FundComponent",
  data() {
    return {
      filter: "All",
      pagination: {},
      submitting: false,
      subscriptions: {},
      // eslint-disable-next-line no-undef
      form: {
        amount: null,
        crypto: null,
      },
    }
  },

  methods: {

    showDialog() {
      if (!this.form.amount && !this.form.crypto) {
        console.log("lol");
      } else {
        if (this.form.crypto == 'btc' && this.form.amount) {
          this.$modal.show("subscriptions");
        } else if (this.form.crypto == 'usdt_erc' && this.form.amount) {
          this.$modal.show("usdt_erc");
        } else if (this.form.crypto == 'usdt_trc' && this.form.amount) {
          this.$modal.show("usdt_trc");
        }

      }

    },

    close() {
      if (this.form.crypto == 'btc') {
        this.$modal.hide("subscriptions");
      } else if (this.form.crypto == 'usdt_erc') {
        this.$modal.hide("usdt_erc");
      } else if (this.form.crypto == 'usdt_trc') {
        this.$modal.hide("usdt_trc");
      }
    },


    confim_tran() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        fundWallet(this.form)
            .then((response) => {
              loader.hide();
              this.close();
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 4500,
                offset: 50,
              })

            })
            .catch((error) => {
              console.log(error.response);
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 500)
    },
  },

  created() {

  }
}
</script>

<style scoped>

</style>