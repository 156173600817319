<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">textsms</md-icon>
          </div>
          <div class="text-black">Send Bulk SMS with SMTP
            <div class="page-title-subheading">
              Send SMS to Valid Phone Numbers for Newsletters
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
        name="subscriptions"
        :adaptive="true"
        :scrollable="true"
        height="auto"
    >
      <div class="mt-3 text-center">
        <h4>
          Transfer ${{ form.amount }} to the BTC address below
        </h4>
      </div>

      <div class="container">
        <div class="text-center mt-5">
          <div>
            <img style="height: 400px"
                 src="../../../../public/assets/images/qr-bc1qmlwhjpv8jzsfd744kav3a3vkwaqfkfxvca82vx.png" alt="">

            <div class="mt-3 mb-3">
              BTC Address: bc1qmlwhjpv8jzsfd744kav3a3vkwaqfkfxvca82vx
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-12" style="text-align: initial; justify-content: space-between; display: flex;">
                <md-button class="md-dense md-raised md-accent" @click="close">Cancel</md-button>
                <md-button class="md-dense md-raised md-primary" @click="confim_tran">Confirm ${{
                    form.amount
                  }}
                </md-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </modal>

    <modal
        name="usdt_erc"
        :adaptive="true"
        :scrollable="true"
        height="auto"
    >
      <div class="mt-3 text-center">
        <h4>
          Transfer ${{ form.amount }} to the USDT(ERC 20) address below
        </h4>
      </div>

      <div class="container">
        <div class="text-center mt-5">
          <div>
            <img style="height: 400px"
                 src="../../../../public/assets/images/qr-0x7f400C9c84c064bb1380D41a0f5E03DcC6aF529a.png" alt="">

            <div class="mt-3 mb-3">
              USDT(ERC 20) Address: 0x7f400C9c84c064bb1380D41a0f5E03DcC6aF529a
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-12" style="text-align: initial; justify-content: space-between; display: flex;">
                <md-button class="md-dense md-raised md-accent" @click="close">Cancel</md-button>
                <md-button class="md-dense md-raised md-primary" @click="confim_tran">Confirm ${{
                    form.amount
                  }}
                </md-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </modal>

    <modal
        name="usdt_trc"
        :adaptive="true"
        :scrollable="true"
        height="auto"
    >
      <div class="mt-3 text-center">
        <h4>
          Transfer ${{ form.amount }} to the USDT(TRC 20) address below
        </h4>
      </div>

      <div class="container">
        <div class="text-center mt-5">
          <div>
            <img style="height: 400px"
                 src="../../../../public/assets/images/qr-TKfp8ndw8218r3HY6K5ywHLEJ1eXFrhQ6S.png" alt="">

            <div class="mt-3 mb-3">
              USDT(TRC 20) Address: TKfp8ndw8218r3HY6K5ywHLEJ1eXFrhQ6S
            </div>
          </div>
          <div>
            <div class="row">
              <div class="col-md-12" style="text-align: initial; justify-content: space-between; display: flex;">
                <md-button class="md-dense md-raised md-accent" @click="close">Cancel</md-button>
                <md-button class="md-dense md-raised md-primary" @click="confim_tran">Confirm ${{
                    form.amount
                  }}
                </md-button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </modal>


    <div class="row" v-if="user_smtp_sub">

      <div class="container mb-4 ">
        <div class="row justify-content-between">
          <div class="">
            <md-button v-if="!currentsmtpbox" @click="opencurrentsmtp" class="md-raised md-primary"
            >
              View Current SMTP
            </md-button>

            <md-button v-if="currentsmtpbox" @click="closecurrentsmtp" class="md-icon-button md-raised md-primary"
            >
              <md-icon>close</md-icon>
            </md-button>
          </div>
          <div class="">
            <md-button v-if="!smtpbox" @click="opensmtp" class="md-raised md-primary"
            >
              Configure SMTP
            </md-button>

            <md-button v-if="smtpbox" @click="closesmtp" class="md-icon-button md-raised md-primary"
            >
              <md-icon>close</md-icon>
            </md-button>
          </div>
        </div>

      </div>

      <div v-if="smtpbox" class="col-md-12 mb-5">
        <md-card class="round-card">
          <md-card-header>
            <div class="md-title">Configure Your SMS SMTP Gateway</div>
          </md-card-header>

          <md-card-content>
            <div class="mt-3">
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <md-field>
                      <label for="movie">Mail Driver / Transport</label>
                      <md-select v-model="form.transport" name="movie" id="movie">
                        <md-option value="smtp">SMTP</md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail Host</label>
                      <md-input v-model="form.host"></md-input>
                      <span class="md-helper-text">SMTP host e.g (smtp.xxxx.xxx)</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail Port</label>
                      <md-input v-model="form.port"></md-input>
                      <span class="md-helper-text">SMTP PORT e.g (465,XXX)</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail Username</label>
                      <md-input v-model="form.username"></md-input>
                      <span class="md-helper-text">smtp email( goodness@whatever.com )</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail Password</label>
                      <md-input v-model="form.password"></md-input>
                      <span class="md-helper-text">smtp email password( AAbbc123@ )</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label for="movie">Mail Encryption</label>
                      <md-select v-model="form.encryption" name="movie" id="movie">
                        <md-option value="ssl">SSL</md-option>
                        <md-option value="tls">TLS</md-option>
                        <md-option value="STARTTLS">STARTTLS</md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail From Email Address</label>
                      <md-input v-model="form.from_address"></md-input>
                      <span
                          class="md-helper-text">SMTP from address such as (3046023408 or spaxxingemail@email.net)</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail From Name</label>
                      <md-input v-model="form.from_name"></md-input>
                      <span class="md-helper-text">SMTP from name e.g(John from BofA, 3046023408)</span>
                    </md-field>
                  </div>

                </div>
              </div>
            </div>
          </md-card-content>

          <div class=" text-center mt-5 mb-5">
            <md-button class="md-primary md-raised md-dense" @click="addsmtp()">Add SMTP</md-button>
          </div>
        </md-card>
      </div>

      <div v-if="currentsmtpbox" class="col-md-12 mb-5">
        <md-card class="round-card">
          <md-card-header>
            <div class="md-title">Your Current SMTP Configurations</div>
          </md-card-header>

          <md-card-content>
            <div class="mt-3">
              <div class="container">
                <div class="row">
                  <div class="col-md-3">
                    <md-field>
                      <label for="movie">Mail Driver / Transport</label>
                      <md-select disabled v-model="form.transport" name="movie" id="movie">
                        <md-option value="smtp">SMTP</md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail Host</label>
                      <md-input disabled v-model="form.host"></md-input>
                      <span class="md-helper-text">SMTP host e.g (smtp.xxxx.xxx)</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail Port</label>
                      <md-input disabled v-model="form.port"></md-input>
                      <span class="md-helper-text">SMTP PORT e.g (465,XXX)</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail Username</label>
                      <md-input disabled v-model="form.username"></md-input>
                      <span class="md-helper-text">smtp email( goodness@whatever.com )</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail Password</label>
                      <md-input disabled v-model="form.password"></md-input>
                      <span class="md-helper-text">smtp email password( AAbbc123@ )</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label for="movie">Mail Encryption</label>
                      <md-select disabled v-model="form.encryption" name="movie" id="movie">
                        <md-option value="ssl">SSL</md-option>
                        <md-option value="tls">TLS</md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail From Email Address</label>
                      <md-input disabled v-model="form.from_address"></md-input>
                      <span
                          class="md-helper-text">SMTP from address such as (3046023408 or spaxxingemail@email.net)</span>
                    </md-field>
                  </div>

                  <div class="col-md-3">
                    <md-field>
                      <label>Mail From Name</label>
                      <md-input disabled v-model="form.from_name"></md-input>
                      <span class="md-helper-text">SMTP from name e.g(John from BofA, 3046023408)</span>
                    </md-field>
                  </div>

                </div>
              </div>
            </div>
          </md-card-content>

        </md-card>
      </div>

      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5">
          <md-ripple>
            <md-card-header>
              <div class="md-title text-capitalize">Send SMS with SMTP to Lead Phone Numbers</div>
            </md-card-header>

            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="row">

                    <div class="col-md-4">
                      <md-field>
                        <label>Phone Numbers</label>
                        <md-input v-model="form.numbers" :placeholder="carrierNote"></md-input>
                        <span class="md-helper-text">Input 500 Numbers at once</span>
                      </md-field>
                    </div>

                    <div class="col-md-4">
                      <md-field>
                        <label>Subject</label>
                        <md-input v-model="form.subject"></md-input>
                        <span class="md-helper-text">Subject of your text</span>
                      </md-field>
                    </div>

                    <div class="col-md-4">
                      <md-field>
                        <label>Message Body</label>
                        <md-textarea v-model="form.message_body" md-autogrow></md-textarea>
                      </md-field>
                    </div>

                    <div class="col-md-6 mt-3">
                      <md-field>
                        <label for="movie">Country</label>
                        <md-select v-model="selectedCountry" name="movie" id="movie">
                          <md-option v-for="country in countries" :value="country.name" :key="country.id">
                            {{ country.name }}
                          </md-option>
                        </md-select>
                      </md-field>
                    </div>

                    <div class="col-md-6 mt-3">
                      <md-field>
                        <label for="movie">Available Carriers</label>
                        <md-select @change="logSelectedData" v-model="selectedCarrier" name="movie" id="movie">
                          <md-option v-for="carrier in carriers" :key="carrier.id" :value="carrier.id">
                            {{ carrier.name }}
                          </md-option>
                          <!--                          <md-option value="tmomail.net">T-Mobile</md-option>-->
                        </md-select>
                      </md-field>
                    </div>


                  </div>

                </div>


                <div class="mt-5">
                  <div class="text-center">
                    <!--                      <md-button disabled="" @click="submit()" class="md-raised text-white"-->
                    <!--                                 style="background-color: green !important;">-->
                    <!--                        Send SMS with twilio-->
                    <!--                      </md-button>-->
                    <!--                      <md-button @click="submitVonage()" class="md-raised text-white"-->
                    <!--                                 style="background-color: green !important;">-->
                    <!--                        Send SMS with Vonage-->
                    <!--                      </md-button>-->
                    <md-button @click="submitwithSMTP()" class="md-raised text-white"
                               style="background-color: green !important;">
                      Send SMS
                    </md-button>

                  </div>

                </div>

              </div>

              <div class="container mt-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="float-start">
                      Total Numbers : {{ results.length }}
                    </div>
                  </div>

                </div>

              </div>

              <div class="mt-3">
                <div>
                  <md-table>
                    <md-table-row>
                      <md-table-head md-numeric>ID</md-table-head>
                      <md-table-head>Number</md-table-head>
                      <md-table-head>Status</md-table-head>
                    </md-table-row>


                    <md-table-row v-for="(data, row) in results" :key="data.id">

                      <md-table-cell md-numeric>{{ row + 1 }}</md-table-cell>
                      <md-table-cell>{{ data.number }}</md-table-cell>
                      <md-table-cell>{{ data.message }}</md-table-cell>
                    </md-table-row>
                  </md-table>
                </div>
              </div>


            </md-card-content>
          </md-ripple>
        </md-card>
      </div>


    </div>

    <div class="row" v-if="!user_smtp_sub">

      <div class="col-md-12 mb-5">
        <md-card class="round-card">
          <md-card-header>
            <div class="md-title">
              SMTP to SMS not yet activated
            </div>
          </md-card-header>

          <md-card-content>
            <div class="mt-3">
              <div class="container">
                <div class="text-center mb-3">
                  Your SMTP to SMS has not been activated. Kindly Activate it below
                </div>

                <div class="text-center">
                  Since the gas fee of BTC is very high now, if your deposit is less than 1,000 US
                  dollars, it is recommended that you choose other currencies.
                </div>
                <div class="text-center mb-5">
                  由于现在BTC的汽油费非常高，如果您充值少于1000美金，建议您选择其他币种。
                </div>


                <div class="row">

                  <div class="col-md-6">
                    <md-field>
                      <label for="movie">Amount</label>
                      <md-select v-model="form.amount" name="movie" id="movie">
                        <md-option value="350">$350</md-option>
                      </md-select>
                    </md-field>
                  </div>

                  <div class="col-md-6">
                    <md-field>
                      <label for="movie">Crypto Currency</label>
                      <md-select v-model="form.crypto" name="movie" id="movie">
                        <md-option value="btc">BTC</md-option>
                        <md-option value="usdt_erc">USDT (ERC20)</md-option>
                        <md-option value="usdt_trc">USDT (TRC20)</md-option>
                      </md-select>
                    </md-field>
                  </div>


                </div>
              </div>
              <div class="mt-5">
                <div class="text-center">
                  <!--                      <md-button disabled="" @click="submit()" class="md-raised text-white"-->
                  <!--                                 style="background-color: green !important;">-->
                  <!--                        Send SMS with twilio-->
                  <!--                      </md-button>-->
                  <!--                      <md-button @click="submitVonage()" class="md-raised text-white"-->
                  <!--                                 style="background-color: green !important;">-->
                  <!--                        Send SMS with Vonage-->
                  <!--                      </md-button>-->
                  <md-button @click="showDialog" class="md-raised text-white"
                             style="background-color: green !important;">
                    Activate
                  </md-button>

                </div>

              </div>
            </div>
          </md-card-content>

        </md-card>
      </div>

    </div>


  </div>
</template>

<script>
import {activateSmtp, addSmtp, getCurrentSmtp, getUserSmtpSubscription, sendWithSmtp} from "@/services/sms/smsService";
import {getCarrierByCountry, loadCountry} from "@/services/settings/settingService";

export default {
  name: "SendSmsSmtpComponent",
  data() {
    return {
      // eslint-disable-next-line no-undef
      form: {
        transport: "",
        host: "",
        port: "",
        username: "",
        password: "",
        encryption: "",
        from_address: "",
        from_name: "",
        numbers: "",
        subject: "",
        message_body: "",
        country: "",
        carrier: "",
        amount: null,
        crypto: null,
      },
      index: 1,
      smtpbox: false,
      currentsmtpbox: false,
      user_smtp_sub: false,
      results: [],
      user_smtp: [],
      filtered_results: [],
      countries: [],
      filtered: false,
      submitting: false,
      selectedCountry: '',
      selectedCarrier: '',
      carriers: [],
      carrierNote: ''
    }
  },

  watch: {
    selectedCountry: function (newOption, oldOption) {
      this.selectedCarrier = '';
      if (newOption !== oldOption) {
        getCarrierByCountry(newOption)
            .then((response) => {
              this.carriers = response.data.data;
            })
            .catch()
      }
    },
    selectedCarrier(newValue) {
      const selectedItem = this.carriers.find(item => item.id === newValue)
      this.carrierNote = selectedItem  ? selectedItem.note : ''
      // console.log(selectedItem);
    },
  },

  methods: {

    submitwithSMTP() {
      this.form.country = this.selectedCountry;
      this.form.carrier = this.selectedCarrier;
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        sendWithSmtp(this.form)
            .then((response) => {
              loader.hide();
              // this.convert(response.data.data);
              this.results = response.data.data;
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })
            })
            .catch((error) => {
              console.log(error.response);
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.errors) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.errors[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 1000)
    },


    opensmtp() {
      Object.keys(this.form).forEach((i) => this.form[i] = "");
      this.smtpbox = true;
      this.closecurrentsmtp();
    },

    closesmtp() {
      this.smtpbox = false;
      this.getCurrent();
    },

    opencurrentsmtp() {
      this.currentsmtpbox = true;
      this.closesmtp();
      this.getCurrent();
    },

    closecurrentsmtp() {
      this.currentsmtpbox = false;
    },

    addsmtp() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        addSmtp(this.form)
            .then((response) => {
              loader.hide();
              this.getCurrent();
              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })
              Object.keys(this.form).forEach((i) => this.form[i] = null);
            }).catch((error) => {
          loader.hide();
          this.$Progress.fail();
          if (error.response.status == 422) {
            for (let i in error.response.data.error) {
              setTimeout(() => {
                this.$notify({
                  message: error.response.data.error[i][0],
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }, 500);
            }
          }

          if (error.response.status == 401) {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            })
          }

          if (error.response.status == 500) {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            })
          }
          this.submitting = false
        })
      }, 1000)
    },

    getCurrent() {
      getCurrentSmtp(this.form).then((response) => {
        this.form.transport = response?.data?.data?.transport;
        this.form.host = response?.data?.data?.host;
        this.form.port = response?.data?.data?.port;
        this.form.username = response?.data?.data?.username;
        this.form.password = response?.data?.data?.password;
        this.form.encryption = response?.data?.data?.encryption;
        this.form.from_address = response?.data?.data?.from_address;
        this.form.from_name = response?.data?.data?.from_name;
        this.$notify({
          message: response.data.message,
          type: "success",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      }).catch((error) => {
        // console.log('new error',error)
        this.$notify({
          message: error.response.data.message,
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      })
    },

    getSubscriptionStatus() {
      getUserSmtpSubscription().then((response) => {
        this.user_smtp_sub = response.data.data;
        this.$notify({
          message: response.data.message,
          type: "success",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });

      }).catch((error) => {
        this.$notify({
          message: error.response.data.message,
          type: "error",
          top: true,
          bottom: false,
          left: false,
          right: true,
          showClose: false,
          closeDelay: 4500,
          offset: 50,
        });
      })
    },

    showDialog() {
      if (!this.form.amount && !this.form.crypto) {
        console.log("lol");
      } else {
        if (this.form.crypto == 'btc' && this.form.amount) {
          this.$modal.show("subscriptions");
        } else if (this.form.crypto == 'usdt_erc' && this.form.amount) {
          this.$modal.show("usdt_erc");
        } else if (this.form.crypto == 'usdt_trc' && this.form.amount) {
          this.$modal.show("usdt_trc");
        }

      }

    },

    close() {
      if (this.form.crypto == 'btc') {
        this.$modal.hide("subscriptions");
      } else if (this.form.crypto == 'usdt_erc') {
        this.$modal.hide("usdt_erc");
      } else if (this.form.crypto == 'usdt_trc') {
        this.$modal.hide("usdt_trc");
      }
    },

    getValidationClass(fieldName) {
      const field = this.form.errors.has(fieldName);
      if (field) {
        return "md-invalid";
      }
    },

    confim_tran() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        activateSmtp(this.form).then((response) => {
          loader.hide();
          this.close();
          this.$notify({
            message: response.data.message,
            type: "success",
            top: true,
            bottom: false,
            left: false,
            right: true,
            showClose: false,
            closeDelay: 4500,
            offset: 50,
          })
        }).catch((error) => {
          loader.hide();
          this.$Progress.fail();
          if (error.response.status == 422) {
            for (let i in error.response.data.error) {
              setTimeout(() => {
                this.$notify({
                  message: error.response.data.error[i][0],
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }, 500);
            }
          }

          if (error.response.status == 401) {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            })
          }

          if (error.response.status == 500) {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            })
          }
          this.submitting = false
        })
      }, 500)
    },

    getCountries() {
      loadCountry()
          .then((response) => {
            this.countries = response.data.data;
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    getCarrierByCountry(data) {
      console.log(data)
      getCarrierByCountry(data)
          .then(() => {

          })
          .catch()
    },

    logSelectedData() {
      // this.carrier =  this.carriers.find(item => item.id === this.selectedCarrier);
      const selectedItem = this.carriers.find(item => item.id === this.selectedCarrier)
      this.carrierNote = selectedItem  ? selectedItem.note : ''
    },
  },

  created() {
    this.getCurrent();
    this.getSubscriptionStatus();
    this.getCountries();
  }
}
</script>

<style lang="scss" scoped>

</style>