<template>
<div>
  <md-dialog :md-active.sync="showDialog" class="round-card mobile_effect">
    <div class="mt-3 row justify-content-between">
      <md-dialog-title class="text-capitalize">{{ title }}</md-dialog-title>
      <md-dialog-title class="text-capitalize ">

        <md-button @click="showDialog = false" class="md-icon-button md-dense md-raised md-accent">
          <md-icon>close</md-icon>
        </md-button>
      </md-dialog-title>
    </div>


    <md-dialog-content class="scrollable-container">
      <productDetailsModalComponent
          :data="data"
      />

    </md-dialog-content>

  </md-dialog>

  <div @click="showDialog = true">Buy</div>
</div>
</template>

<script>
import productDetailsModalComponent from "@/components/utils/productDetailsModalComponent";
export default {
  name: "productDetailsComponent",
  components: {
    productDetailsModalComponent
  },
  props: {
    title: String,
    data: Object,
  },
  data: () => ({
    showDialog: false,
  }),

  methods:{
    test(){
      alert("lol")
    }
  }
}

</script>

<style>
.round-card > div {
  border-radius: 20px;

  @media (max-width: 576px) {
    border-radius: 12px 12px 0 0;
  }
}

.mobile_effect{
  @media (max-width: 576px) {
    margin-top: 70px
  }
}


/* Modify scrollbar appearance */
.scrollable-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollable-container::-webkit-scrollbar-track {
  background-color: #f1f1f1; /* Track color */
  border-radius: 8px;
}

.scrollable-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Thumb color */
  border-radius: 4px; /* Rounded corners */
}

.scrollable-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Hover color */
}

</style>