/* eslint-disable */
// noinspection DuplicatedCode

import {Form} from 'vform'
import axios from "axios";
import {GET_HEADERS, POST_HEADERS} from "@/config/axios_config";

let BASE_API_URL;
const form = new Form();


    BASE_API_URL = process.env.VUE_APP_API_BASE_URL;


export async function portNumber(data) {
    form.originalData = data
    let keys = Object.keys(data);
    keys.forEach((key) => {
        form[`${key}`] = data[key]
    })
    return form.post(BASE_API_URL + '/numbers/port', POST_HEADERS)
}

export async function getCallInfo(call_id, refresh) {
    return await axios.get(BASE_API_URL + '/numbers/call/info/' + call_id, GET_HEADERS);
}

export async function getCallRecording(id) {
    return await axios.get(BASE_API_URL + '/numbers/call/recording/' + id, GET_HEADERS);
}

