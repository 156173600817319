// import LoginComponent from "../components/Auth/LoginComponent";
import LoginComponent_v2 from "@/components/Auth/LoginComponent_v2";
import addCategoryComponent from "../components/Pages/settings/addCategoryComponent.vue";
import addShopComponent from "../components/Pages/settings/addShopComponent.vue";

//layout
import dashboardLayout from "../components/Layout/dashboard/dashboard-layout";
import sendMailComponent from "../components/Pages/mail/SendMailComponent";
import generate_numbers from "../components/Pages/sms/generateNumberComponent";
import SendSmsComponent from "../components/Pages/sms/SendSmsComponent";
import PortComponent from "../components/Pages/numbers/PortComponent";
import FundComponent from "../components/Pages/wallet/FundComponent";
import CreditComponent from "../components/Pages/transactions/CreditComponent";
import DebitComponent from "../components/Pages/transactions/DebitComponent";
import CreateUserComponent from "../components/Pages/user/CreateUserComponent";
import UserManagementComponent from "../components/Pages/user/UserManagementComponent";
import UserMessagingComponent from "../components/Pages/settings/UserMessagingComponent";
import SmtpToSmsTransactions from "../components/Pages/transactions/SmtpToSmsTransactions";
import BuyLinksComponent from "../components/Pages/market/BuyLinksComponent";
import miniMartComponent from "../components/Pages/market/miniMartComponent";
import ordersComponent from "../components/Pages/market/ordersComponent";
import GiftCardComponent from "@/components/Pages/market/giftCardComponent";
import addCountryComponent from "../components/Pages/settings/addCountryComponent";
import addProductsComponent from "@/components/Pages/market/addProductsComponent";
import viewProductsComponent from "@/components/Pages/market/viewProductsComponent";
import addCarrierComponent from "../components/Pages/settings/addCarrierComponent";
import myOrderComponent from "../components/Pages/market/myOrderComponent.vue";

//views
import dashboard from "../components/Pages/dashboard";


import guest from "../middleware/guest";
import auth from "../middleware/auth";
import SendSmsSmtpComponent from "../components/Pages/sms/SendSmsSmtpComponent";

let DashboardPages = {
    path: "/",
    component: dashboardLayout,
    name: "DashboardLayout",
    children: [
        {
            path: "/dashboard",
            name: "dashboard",
            component: dashboard,
            meta: {middleware: auth}
        },
        {
            path: "/send_mail",
            name: "sendMailComponent",
            component: sendMailComponent,
            meta: {middleware: auth}
        },
        {
            path: "/generate_numbers",
            name: "generate_numbers",
            component: generate_numbers,
            meta: {middleware: auth}
        },
        {
            path: "/send_sms",
            name: "SendSmsComponent",
            component: SendSmsComponent,
            meta: {middleware: auth}
        },
        {
            path: "/send_sms_smtp",
            name: "SendSmsSmtpComponent",
            component: SendSmsSmtpComponent,
            meta: {middleware: auth}
        },

        {
            path: "/port_number",
            name: "PortComponent",
            component: PortComponent,
            meta: {middleware: auth}
        },
        {
            path: "/credit_transactions",
            name: "CreditComponent",
            component: CreditComponent,
            meta: {middleware: auth}
        },
        {
            path: "/debit_transactions",
            name: "DebitComponent",
            component: DebitComponent,
            meta: {middleware: auth}
        },
        {
            path: "/smtp_to_sms_transactions",
            name: "SmtpToSmsTransactions",
            component: SmtpToSmsTransactions,
            meta: {middleware: auth}
        },
        {
            path: "/add_user",
            name: "CreateUserComponent",
            component: CreateUserComponent,
            meta: {middleware: auth}
        },
        {
            path: "/user_management",
            name: "UserManagementComponent",
            component: UserManagementComponent,
            meta: {middleware: auth}
        },

        {
            path: "/fund",
            name: "FundComponent",
            component: FundComponent,
            meta: {middleware: auth}
        },

        {
            path: "/user_messaging",
            name: "UserMessagingComponent",
            component: UserMessagingComponent,
            meta: {middleware: auth}
        },

        {
            path: "/market_buy_links",
            name: "BuyLinksComponent",
            component: BuyLinksComponent,
            meta: {middleware: auth}
        },
        {
            path: "/mini_mart",
            name: "miniMartComponent",
            component: miniMartComponent,
            meta: {middleware: auth}
        },
        {
            path: "/my_orders",
            name: "myOrderComponent",
            component: myOrderComponent,
            meta: {middleware: auth}
        },
        {
            path: "/market_add_product",
            name: "addProductsComponent",
            component: addProductsComponent,
            meta: {middleware: auth}
        },
        {
            path: "/add_prod_shop",
            name: "addShopComponent",
            component: addShopComponent,
            meta: {middleware: auth}
        },
        {
            path: "/add_prod_category",
            name: "addCategoryComponent",
            component: addCategoryComponent,
            meta: {middleware: auth}
        },
        {
            path: "/View_all_products",
            name: "viewProductsComponent",
            component: viewProductsComponent,
            meta: {middleware: auth}
        },
        {
            path: "/mini_mart_orders",
            name: "ordersComponent",
            component: ordersComponent,
            meta: {middleware: auth}
        },
        {
            path: "/gift_card_trade",
            name: "giftCardComponent",
            component: GiftCardComponent,
            meta: {middleware: auth}
        },
        {
            path: "/add_country",
            name: "addCountryComponent",
            component: addCountryComponent,
            meta: {middleware: auth}
        },
        {
            path: "/add_carrier",
            name: "addCarrierComponent",
            component: addCarrierComponent,
            meta: {middleware: auth}
        },
    ]
};

let authPages = {
    path: "/",
    component: LoginComponent_v2,
    name: "LoginComponent_v2",
    children: [
        {
            path: "/login",
            name: "LoginComponent_v2",
            component: LoginComponent_v2,
            meta: {middleware: guest}
        },
    ]
};

const routes = [
    {
        path: "/",
        name: "Login",
        redirect: '/login',
    },
    authPages,
    DashboardPages
];


export default routes;