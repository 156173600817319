<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">pin</md-icon>
          </div>
          <div class="text-black">Generate Numbers
            <div class="page-title-subheading">
              Generate valid Phone numbers for Newsletters
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>
            <md-card-header>
              <div class="md-title text-capitalize">Generate verified Numbers</div>
            </md-card-header>

            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="row">

                    <div class="col-md-3">
                      <md-field>
                        <label>Country Code</label>
                        <md-input v-model="form.prefix" placeholder="e.g +1"></md-input>
                      </md-field>
                    </div>

                    <div class="col-md-3">
                      <md-field>
                        <label>Area Code / Mobile network code</label>
                        <md-input v-model="form.areacode" placeholder="e.g 304"></md-input>
                      </md-field>
                    </div>

                    <div class="col-md-3">
                      <md-field>
                        <label>Amount</label>
                        <md-input v-model="form.amount"></md-input>
                      </md-field>
                    </div>

                    <div class="col-md-3">
                      <md-field>
                        <label>Remaining Digits</label>
                        <md-input v-model="form.remain"></md-input>
                      </md-field>
                    </div>


                  </div>

                </div>
                <div class="text-center">
                  <md-button @click="submit()" class="md-raised text-white" style="background-color: green !important;">
                    Generate
                  </md-button>
                </div>
              </div>

              <div class="container mt-5">
                <div class="row">
                  <div class="col-md-12">
                    <div class="float-start">
                      Total Numbers Generated : {{ results.length }}
                    </div>
                    <div class="text-center">
                      Total Filtered results : {{ filtered_results.length }}
                    </div>
                    <div class="float-end">
                      <div class="container">
                        <div class="row">
                          <div class="col-md-6">
                            <md-menu>
                              <md-button class="md-icon-button" md-menu-trigger>
                                <md-icon>filter_list</md-icon>
                              </md-button>


                              <md-menu-content>
                                <span class="ml-2">All</span>
                                <md-menu-item>
                                  <md-button class="md-raised md-dense md-primary" @click="reset_filter">
                                    All
                                  </md-button>
                                </md-menu-item>
                                <span class="ml-2">By Carrier Type</span>
                                <md-menu-item>
                                  <md-button class="md-raised md-dense md-primary" @click="filter('mobile')">Mobile
                                  </md-button>
                                </md-menu-item>
                                <md-menu-item>
                                  <md-button class="md-raised md-dense md-primary" @click="filter('landline')">
                                    Landline
                                  </md-button>
                                </md-menu-item>
                                <md-menu-item>
                                  <md-button class="md-raised md-dense md-primary" @click="filter('virtual')">
                                    Voip/ Virtual
                                  </md-button>
                                </md-menu-item>
                                <span class="ml-2">By Carrier Name</span>
                                <md-menu-item>
                                  <md-button class="md-raised md-dense md-primary" @click="filter('T-Mobile')">
                                    T-Mobile
                                  </md-button>
                                </md-menu-item>

                                <md-menu-item>
                                  <md-button class="md-raised md-dense md-primary" @click="filter('Verizon')">
                                    Verizon
                                  </md-button>
                                </md-menu-item>

                                <md-menu-item>
                                  <md-button class="md-raised md-dense md-primary" @click="filter('AT&T')">
                                    AT&T Wireless
                                  </md-button>
                                </md-menu-item>

                              </md-menu-content>
                            </md-menu>
                          </div>
                          <div v-if="filtered" class="col-md-6">
                            <md-button @click="downloadCSVData"> download csv file</md-button>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

              </div>

              <div class="mt-3">
                <div v-if="!filtered">
                  <md-table>
                    <md-table-row>
                      <md-table-head md-numeric>ID</md-table-head>
                      <md-table-head>Phone Number</md-table-head>
                      <md-table-head>Network Type</md-table-head>
                      <md-table-head>Mobile Network Carrier Code</md-table-head>
                      <md-table-head>Mobile Country Code</md-table-head>
                      <md-table-head>With HLR</md-table-head>
                    </md-table-row>


                    <md-table-row v-for="(data, row) in results" :key="data.id">

                      <md-table-cell md-numeric>{{ row + 1 }}</md-table-cell>
                      <md-table-cell>{{ data.formats.international }}</md-table-cell>
                      <md-table-cell>{{ data.type }}</md-table-cell>
                      <md-table-cell>{{ data.hlr ? data.hlr.network : "N/A" }}</md-table-cell>
                      <md-table-cell>{{ data.countryCode }}</md-table-cell>
                      <md-table-cell>{{ data.hlr ? data.hlr : "N/A" }}</md-table-cell>
                    </md-table-row>

                  </md-table>
                </div>

                <div v-if="filtered">
                  <md-table>
                    <md-table-row>
                      <md-table-head md-numeric>ID</md-table-head>
                      <md-table-head>Phone Number</md-table-head>
                      <md-table-head>Network Type</md-table-head>
                      <md-table-head>Mobile Network Carrier Code</md-table-head>
                      <md-table-head>Mobile Country Code</md-table-head>
                      <md-table-head>With HLR</md-table-head>
                    </md-table-row>


                    <md-table-row v-for="(data, row) in results" :key="data.id">

                      <md-table-cell md-numeric>{{ row + 1 }}</md-table-cell>
                      <md-table-cell>{{ data.formats.international }}</md-table-cell>
                      <md-table-cell>{{ data.type }}</md-table-cell>
                      <md-table-cell>{{ data.hlr ? data.hlr.network : "N/A" }}</md-table-cell>
                      <md-table-cell>{{ data.countryCode }}</md-table-cell>
                      <md-table-cell>{{ data.hlr ? data.hlr : "N/A" }}</md-table-cell>
                    </md-table-row>

                  </md-table>
                </div>
              </div>
            </md-card-content>
          </md-ripple>
        </md-card>
      </div>


    </div>


  </div>
</template>

<script>
import {generateNumber} from "@/services/sms/smsService";

export default {
  name: "generate_numbers",
  data() {
    return {
      // eslint-disable-next-line no-undef
      form: {
        areacode: "",
        amount: "",
        prefix: "",
        remain: "",
      },
      index: 1,
      results: [],
      filtered_results: [],
      filtered: false,
      submitting: false,
    }
  },

  methods: {
    submit() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {
        generateNumber(this.form)
            .then((response) => {
              loader.hide();
              this.convert(response.data.data);
              //  this.results = response.data.data;

              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 2500,
                offset: 50,
              })

            })
            .catch((error) => {
              console.log(error.response);
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }

              if (error.response.status == 500) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })
      }, 1000)
    },

    convert(inputObj) {
      const newArr = Object.values(inputObj);
      newArr.forEach(obj => {
        if (obj.descendants) obj.descendants = this.filter_by_type_mobile(obj.descendants);
        if (obj.children) obj.children = this.filter_by_type_mobile(obj.children);
      });
      console.log(newArr);
      return this.results = newArr;


    },

    filter(data) {
      this.filtered = true;
      // alert(data);
      this.filtered_results = this.results.filter((e) => e.type == data);
    },

    reset_filter() {
      this.filtered = false;
      this.filtered_results = [];
    },

    downloadCSVData() {
      const itemsArray = this.filtered_results;

      const csvString = [
        ...itemsArray.map(item => [
          item.formats.e164,
        ])
      ].map(e => e.join(","))
          .join("," + "\n");

      // console.log(csvString);

      const anchor = document.createElement('a');
      anchor.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);
      anchor.target = '_blank';
      anchor.download = this.form.areacode + '_mobile.csv';
      anchor.click();
    }
  }
}
</script>

<style scoped>

</style>