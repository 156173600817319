<template>
  <div class="app-main__inner">
    <div class="app-page-title">
      <div class="page-title-wrapper">
        <div class="page-title-heading">
          <div class="page-title-icon">
            <!--            <i class="pe-7s-config icon-gradient bg-happy-itmeo"></i>-->
            <md-icon class="metismenu-icon icon-gradient bg-happy-itmeo">person_add</md-icon>
          </div>
          <div>User Management
            <div class="page-title-subheading">
              Manage all User
            </div>
          </div>
        </div>
      </div>
    </div>


    <div class="row">
      <div class="col-md-12">
        <md-card md-with-hover class="round-card mb-5 opacity-75">
          <md-ripple>
            <md-card-content>
              <div class="mt-5">
                <div class="container">
                  <div class="mt-5">
                    <md-table>
                      <md-table-row>
                        <md-table-head class="text-center" md-numeric>ID</md-table-head>
                        <md-table-head class="text-center">Username</md-table-head>
                        <md-table-head class="text-center">Email</md-table-head>
                        <md-table-head class="text-center">Type</md-table-head>
                        <md-table-head class="text-center">Status</md-table-head>
                        <md-table-head class="text-center">Actions</md-table-head>
                      </md-table-row>
                      <md-table-row v-for="row in users" :key="row.id">
                        <md-table-cell class="text-center" md-numeric> {{ row.id }}</md-table-cell>
                        <md-table-cell class="text-center"> {{ row.username }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.email }}</md-table-cell>
                        <md-table-cell class="text-center">{{ row.type }}</md-table-cell>
                        <md-table-cell class="text-center">
                          <md-chip :class="getStatusClass(row.status)">{{ row.status }}</md-chip>
                        </md-table-cell>
                        <md-table-cell class="text-center">
                          <div
                              class="btn-group"
                              role="group"
                              aria-label="Basic example"
                          >

                            <md-button
                                v-if="row.status == 'suspended'"
                                class="md-fab md-mini bg-success"
                                @click="changeStatus(row.id, 'active')"

                            >
                              <md-icon>check_circle</md-icon>
                              <md-tooltip md-direction="bottom"
                              >Activate User
                              </md-tooltip
                              >
                            </md-button>

                            <md-button
                                v-if="row.status == 'active'"
                                class="md-fab md-mini bg-danger"
                                @click="changeStatus(row.id, 'suspended')"

                            >
                              <md-icon>close</md-icon>
                              <md-tooltip md-direction="bottom"
                              >Suspend User
                              </md-tooltip
                              >
                            </md-button>

                          </div>
                        </md-table-cell>
                      </md-table-row>
                    </md-table>
                  </div>

                </div>

              </div>

            </md-card-content>

            <div class="col-md-12 mb-3 mt-3">
              <div class="justify-content-center" style="display: flex !important;">
                <div>
                  <pagination
                      :data="pagination"
                      @pagination-change-page="loadUsers"
                      :limit="5"
                      page="1"
                  ></pagination>
                </div>

              </div>
            </div>

          </md-ripple>
        </md-card>
      </div>


    </div>

  </div>
</template>

<script>
import {changeUserStatus, loadUsers} from "@/services/user/userService";

export default {
  name: "UserManagementComponent",

  data() {
    return {
      users: [],
      filter: "All",
      pagination: {},
      submitting: false,
    };
  },

  methods: {

    getStatusClass(status) {
      if (status == 'active') {
        return "bg-success";
      } else if (status == "suspended") {
        return "md-accent";
      }
    },

    prepPagination(data) {
      this.pagination = {
        data: data.data,
        current_page: data.meta.current_page,
        first_item: data.meta.first_item,
        last_item: data.meta.last_item,
        last_page: data.meta.last_page,
        next_page_url: data.meta.next_page_url,
        per_page: data.meta.per_page,
        previous_page_url: data.meta.previous_page_url,
        total: data.meta.total,
      };
    },

    loadUsers(page = 1) {
      loadUsers(page)
          .then((response) => {
            this.prepPagination(response.data);
            this.users = response.data.data;
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    },

    changeStatus(id, status) {
      changeUserStatus(id, status)
          .then(() => {
            this.loadUsers();
          })
          .catch((error) => {
            this.$notify({
              message: error.response.data.message,
              type: "error",
              top: true,
              bottom: false,
              left: false,
              right: true,
              showClose: false,
              closeDelay: 4500,
              offset: 50,
            });
          })
    }


  },

  mounted() {
    this.loadUsers();
  }
}
</script>

<style scoped>

</style>