<template>
  <div>

  </div>
<!--  <div class="app-wrapper-footer">-->
<!--    <div class="app-footer">-->
<!--      <div class="app-footer__inner">-->
<!--      <div class="container">-->
<!--        <div class="text-center">-->
<!--          Copyright © {{ new Date().getFullYear() }} All Rights Reserved by Naruto Mailer Pro.-->
<!--        </div>-->
<!--      </div>-->
<!--      </div>-->
<!--    </div>-->
<!--  </div>-->
</template>

<script>
export default {
  name: "app-footer"
}
</script>

<style scoped>

</style>