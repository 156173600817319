<template>
  <div class="main_wrapper">
    <div class="wrapper">
      <div class="form_wrapper sign_in">
        <form @submit.prevent="login">
          <h2>Sign In</h2>
          <div class="input_group">
            <input v-model="form.email" type="text" name="" required>
            <label for="">Email</label>
          </div>
          <div class="input_group">
            <input v-model="form.password" type="password" name="" required>
            <label for="">Password</label>
          </div>
          <div class="remember_me">
            <label for="">
              <input type="checkbox" name="" id="">
              Remember Me
            </label>
          </div>
          <button type="submit">Login</button>
<!--          <div class="signup_link d-none">-->
<!--            <p>Don't have an Account? <a href="#" class="signUpBtn-link">Sign Up</a></p>-->
<!--          </div>-->
        </form>
      </div>

      <div class="form_wrapper sign_up">
        <form @submit.prevent="register">
          <h2>Join Us</h2>
          <div class="input_group">
            <input v-model="form.email" type="text" name="" required>
            <label for="">Email</label>
          </div>
          <div class="input_group">
            <input v-model="form.password" type="password" name="" required>
            <label for="">Password</label>
          </div>
          <div class="input_group">
            <input v-model="form.password_confirmation" type="password" name="" required>
            <label for="">Confirm Password</label>
          </div>
          <div class="remember_me">
            <label for="">
              <input type="checkbox" name="">
              I agree to the terms and condition
            </label>
          </div>
          <button type="submit">Register</button>
          <div class="signup_link">
            <p>Already have an Account? <a href="#" class="signInBtn-link">Sign In</a></p>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import store from "@/store";
import {loginService, loginSMTPService, registerService} from '@/services/user/userService'

export default {
  name: "LoginComponent_v2",
  data() {
    return {
      // eslint-disable-next-line no-undef
      form: {
        email: "",
        password: "",
        password_confirmation: "",
      },
      submitting: false,
    }
  },

  methods: {
    login() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {

        loginService(this.form)
            .then(response => {
              sessionStorage.setItem('access-token', response.data.data.access_token);

              loginSMTPService(this.form).then(res => {
                loader.hide();
                this.$Progress.finish();
                sessionStorage.setItem('smtp-access-token', res.data.data.access_token);
                this.$notify({
                  message: response.data.message,
                  type: "success",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 2500,
                  offset: 50,
                })
                store.dispatch({
                  type: 'updateUser',
                  data: response.data.data.user
                })
                // redirect to dashboard page
                setTimeout(() => {
                  window.location.href = "/dashboard"
                }, 800);
              }).catch(err => {
                console.log(err)
              })


            })
            .catch(error => {
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })

      }, 2000)
    },

    register() {
      this.$Progress.start();
      let loader = this.$loading.show({});
      this.submitting = true;
      setTimeout(() => {

        registerService(this.form)
            .then(response => {
              loader.hide();
              this.$Progress.finish();

              this.$notify({
                message: response.data.message,
                type: "success",
                top: true,
                bottom: false,
                left: false,
                right: true,
                showClose: false,
                closeDelay: 3500,
                offset: 50,
              })

              Object.keys(this.form).forEach((i) => this.form[i] = null);
            })
            .catch(error => {
              loader.hide();
              this.$Progress.fail();
              if (error.response.status == 422) {
                for (let i in error.response.data.error) {
                  setTimeout(() => {
                    this.$notify({
                      message: error.response.data.error[i][0],
                      type: "error",
                      top: true,
                      bottom: false,
                      left: false,
                      right: true,
                      showClose: false,
                      closeDelay: 4500,
                      offset: 50,
                    })
                  }, 500);
                }
              }

              if (error.response.status == 401) {
                this.$notify({
                  message: error.response.data.message,
                  type: "error",
                  top: true,
                  bottom: false,
                  left: false,
                  right: true,
                  showClose: false,
                  closeDelay: 4500,
                  offset: 50,
                })
              }
              this.submitting = false
            })

      }, 2000)
    },

  },

  mounted() {
    const signInBtnLink = document.querySelector('.signInBtn-link');
    const signUpBtnLink = document.querySelector('.signUpBtn-link');
    const wrapper = document.querySelector('.wrapper')

    signUpBtnLink.addEventListener('click', () => {
      wrapper.classList.toggle('active')
    })

    signInBtnLink.addEventListener('click', () => {
      wrapper.classList.toggle('active')
    })
  }
}
</script>

<style scoped>
.main_wrapper {
  background-color: black;
  /*height: 100%;*/
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.wrapper {
  position: relative;
  width: 400px;
  background: #000;
  box-shadow: 0 0 50px #0ef;
  border-radius: 20px;
  padding: 40px;
  overflow: hidden;
}

.wrapper:hover {
  animation: animate 1s linear infinite;
}

@keyframes animate {
  100% {
    filter: hue-rotate(360deg);
  }
}

.form_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: 1s ease-in-out;
}

.wrapper.active .form_wrapper.sign_in {
  transform: translateY(-450px);
}

.wrapper .form_wrapper.sign_up {
  position: absolute;
  top: 450px;
  left: 0;
}

.wrapper.active .form_wrapper.sign_up {
  transform: translateY(-450px);
}

h2 {
  font-size: 30px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;
  margin-top: 20px;
}

.input_group {
  position: relative;
  margin: 30px 0;
  border-bottom: 2px solid #ffffff;
}

.input_group label {
  position: absolute;
  top: 50%;
  left: 5px;
  transform: translateY(-50%);
  font-size: 16px;
  color: white;
  pointer-events: none;
  transition: .5s;
}

.input_group input {
  width: 320px;
  height: 40px;
  font-size: 16px;
  color: white;
  padding: 0 5px;
  background: transparent;
  border: none;
  outline: none;
}

.input_group input:focus ~ label,
.input_group input:valid ~ label {
  top: -5px
}

.remember_me {
  margin: 9px 0 15px 5px;
}

.remember_me label {
  color: white;
  font-size: 14px;
}

.remember_me label input {
  accent-color: #0ef;
}

button {
  position: relative;
  width: 100%;
  height: 40px;
  background: #0ef;
  box-shadow: 0 0 10px #0ef;
  font-size: 16px;
  color: black;
  font-weight: 500;
  cursor: pointer;
  border-radius: 30px;
  border: none;
  outline: none;
}

.signup_link {
  font-size: 14px;
  text-align: center;
  margin: 15px 0 30px;
}

.signup_link p {
  color: #fff;
}

.signup_link p a {
  color: #0ef;
  text-decoration: none;
  font-weight: 500;
}

.signup_link p a:hover {
  color: #fff;
}
</style>
